import { Divider } from '@remarkable/ark-web';
import { useSearch } from '@tanstack/react-router';

import { Modal } from 'src/components';

import { InviteByEmail } from './InviteToConnectForBusinessByEmail';

export const InviteModal = () => {
  return (
    <Modal.Content title="Invite">
      <Divider className="my-16" />

      <ModalRouter />
    </Modal.Content>
  );
};

const ModalRouter = () => {
  const { invite_by } = useSearch({ strict: false });

  switch (invite_by) {
    case 'email':
      return <InviteByEmail />;
    case 'import':
      return (
        <div className="flex h-full min-h-[230px] items-center justify-center">
          <div className=" text-gray-500">
            This option will be available soon
          </div>
        </div>
      );
    default:
      return null;
  }
};
