import { cloudEnterpriseAdminApiClient } from '../clients/cloudEnterpriseAdminApiClient';

export const getRoles = () =>
  cloudEnterpriseAdminApiClient
    .GET('/admin/v1/roles')
    .then((r) => r.data ?? null);

export const getRolesByUser = (vars: { memberId: string }) =>
  cloudEnterpriseAdminApiClient
    .GET('/admin/v1/members/{memberId}/roles', {
      params: {
        path: {
          memberId: vars.memberId,
        },
      },
    })
    .then((r) => r.data ?? null);

export const addRoleOnUser = (vars: { memberId: string; roleId: string }) =>
  cloudEnterpriseAdminApiClient.PUT(
    '/admin/v1/members/{memberId}/roles/{roleId}',
    {
      params: {
        path: {
          memberId: vars.memberId,
          roleId: vars.roleId,
        },
      },
    }
  );

export const deleteRoleOnUser = (vars: { memberId: string; roleId: string }) =>
  cloudEnterpriseAdminApiClient.DELETE(
    '/admin/v1/members/{memberId}/roles/{roleId}',
    {
      params: {
        path: {
          memberId: vars.memberId,
          roleId: vars.roleId,
        },
      },
    }
  );
