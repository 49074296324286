import { useMemo } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { storeApi } from 'src/api/endpoints';
import { FeedbackTargetValues } from 'src/api/endpoints/storeApi.types';
import {
  queryKeys,
  usePaymentHistory,
  useStripeSubscription,
} from 'src/api/queries';
import { PaymentHistoryFAQ } from 'src/apps/manageSubscription/app/components/PaymentHistoryFAQ';
import { Button, Divider, FaqSection, Feedback, Sheet } from 'src/components';
import { FAQ } from 'src/constants/FAQ';
import { subscriptionTrialWillCancel } from 'src/utils/subscriptionTrialWillCancel';
import { useFeatureToggles } from 'src/utils/useFeatureToggles';

import { BillingInfoCard } from '../components/BillingInfoCard';
import { ChangePaymentInterval } from '../components/ChangePaymentInterval';
import { NextBillingCard } from '../components/NextBillingCard';
import { PaymentCard } from '../components/PaymentCard';
import { PaymentHistoryCard } from '../components/PaymentHistoryCard';

export const PaymentPage = () => {
  const subscription = useStripeSubscription();
  const featureToggles = useFeatureToggles();
  const isSeatsSubscription = subscription.data?.managementType === 'seats';
  const paymentHistory = usePaymentHistory();
  const queryClient = useQueryClient();
  const hasPaymentAccess =
    subscription.data?.access.canChangePaymentInformation ||
    subscription.data?.access.canSeeNextInvoiceAmount ||
    subscription.data?.access.canSeeFailedPaymentInformation ||
    subscription.data?.access.canSeePaymentHistory ||
    subscription.data?.access.canSeePaymentInformation;

  // If there is a subscription, we want to check access, otherwise any payment history is fine
  const canSeePaymentHistory = (() => {
    if (!subscription.isSuccess) {
      return false;
    }

    if (!subscription.data) {
      return !!paymentHistory.data?.length;
    }

    return subscription.data.access.canSeePaymentHistory;
  })();

  const feedbackTargetId = useMemo((): FeedbackTargetValues | null => {
    if (!subscription.data) return null;

    if (subscription.data.managementType === 'simple') {
      return 'connect-payment-page-v1';
    }

    if (subscription.data.access.canSeeMembers) {
      return 'c4b-payment-page-for-owner-v1';
    }

    return 'c4b-payment-page-for-members-v1';
  }, [subscription.data]);

  const switchCollectionMethod = useMutation({
    mutationFn: storeApi.updateCollectionMethod,
    onError: () => toast.error('Unable to change billing method'),
    onSuccess: async () => {
      toast.success('Succeded');
      await queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
  return (
    <div className="flex flex-col gap-24 lm:gap-32">
      {subscription.data?.access.canSeeNextInvoiceAmount && <NextBillingCard />}

      {subscription.data?.access.canChangePaymentInformation && (
        <Sheet className="gap-24 lm:gap-32">
          {subscription.data.paymentInformation?.payment?.type === 'card' ? (
            <>
              <PaymentCard />
              {featureToggles.EnableSwitchCollectionMethod &&
              isSeatsSubscription ? (
                <>
                  <Divider />

                  <Button
                    variant="tertiary"
                    size="medium"
                    onClick={() => {
                      if (!subscription.data?.id) return;
                      switchCollectionMethod.mutate({
                        subscriptionId: subscription.data.id,
                        payByInvoice: true,
                      });
                    }}
                  >
                    pay by invoice
                  </Button>
                </>
              ) : null}
            </>
          ) : (
            <Button
              variant="tertiary"
              size="medium"
              onClick={() => {
                if (!subscription.data?.id) return;
                switchCollectionMethod.mutate({
                  subscriptionId: subscription.data.id,
                  payByInvoice: false,
                });
              }}
            >
              pay by card
            </Button>
          )}
        </Sheet>
      )}

      <Sheet className="gap-24 lm:gap-32">
        <BillingInfoCard />
      </Sheet>

      {subscription.data?.access.canChangePaymentInterval &&
        !subscriptionTrialWillCancel(subscription.data) && (
          <ChangePaymentInterval />
        )}

      {canSeePaymentHistory && (
        <>
          <PaymentHistoryCard />
        </>
      )}

      {feedbackTargetId && (
        <Feedback
          feedbackTargetId={feedbackTargetId}
          title="Did you find what you were looking for?"
        />
      )}

      {isSeatsSubscription && hasPaymentAccess ? (
        <FaqSection
          className="my-96"
          componentLocation={ComponentLocations.SUBSCRIPTION.PAYMENT_PAGE}
          sections={[
            FAQ.connectWithSeats.whatPaymentOptionsDoYouOffer(),
            FAQ.connectWithSeats.whyWasMyPaymentDeclined(),
            FAQ.connectWithSeats.howOftenWillIBeChargedForConnectWithSeats(),
            FAQ.connectWithSeats.whyWasMyBillingDifferentThanExpected(),
          ]}
        />
      ) : (
        <PaymentHistoryFAQ />
      )}
    </div>
  );
};
