import { useEffect } from 'react';

import { Typography } from '@remarkable/ark-web';
import { Controller, useForm } from 'react-hook-form';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { CreateEmailsError } from 'src/api/queries';
import { useCreateEnterpriseInvitations } from 'src/api/queries/enterpriseInvitations';
import { Button, Form, NotificationBox } from 'src/components';
import { validateEmail } from 'src/utils/validateEmail';

import {
  InputMultiple,
  ValidationError,
  validateEmails,
} from './InputMultiple';

const inputEmailsFormKey = 'emails';
interface InviteByEmailForm {
  [inputEmailsFormKey]: { email: string }[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InviteToEnterpriseByEmail = () => {
  const createEnterpriseInvitationsByEmail = useCreateEnterpriseInvitations();

  const form = useForm<InviteByEmailForm>({
    defaultValues: {
      [inputEmailsFormKey]: [],
    },
  });

  const {
    formState: { isSubmitted, isSubmitSuccessful },
  } = form;

  const emails = form.watch(inputEmailsFormKey);
  const numberOfEmails = emails?.length ?? 0;

  const onSubmit = (data: InviteByEmailForm) => {
    if (createEnterpriseInvitationsByEmail.isPending) {
      return;
    }

    // Remove success notification if the form has already been submitted
    if (createEnterpriseInvitationsByEmail.isSuccess) {
      createEnterpriseInvitationsByEmail.reset();
    }

    return createEnterpriseInvitationsByEmail.mutate(
      {
        emails: data.emails?.map((item) => item.email) ?? [],
      },
      {
        onError(error) {
          if (error instanceof CreateEmailsError) {
            // Reset the form with the emails that failed, so that the user can
            // try to resend them.
            form.reset({
              [inputEmailsFormKey]: error.result.unsuccessful.map((item) => ({
                email: item.email,
              })),
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (
      isSubmitted &&
      isSubmitSuccessful &&
      createEnterpriseInvitationsByEmail.isSuccess
    ) {
      // Reset the form if the submission was successful.
      form.reset({ [inputEmailsFormKey]: [] });
    }
  }, [
    isSubmitted,
    isSubmitSuccessful,
    createEnterpriseInvitationsByEmail.isSuccess,
  ]);

  const emailsSubmittedCount =
    createEnterpriseInvitationsByEmail.variables?.emails.length ?? 0;

  const failedEmails =
    createEnterpriseInvitationsByEmail.error instanceof CreateEmailsError
      ? createEnterpriseInvitationsByEmail.error.result.unsuccessful.map(
          (item) => item.email
        )
      : [];

  return (
    <div className="text-left">
      <Typography variant="body-md-regular" className="mb-16">
        Invite people via email to join your subscription.
      </Typography>
      <Form
        onSubmit={onSubmit}
        onInvalid={() => {
          // Remove query success and error states if the user interacts with
          // the form again.
          if (
            createEnterpriseInvitationsByEmail.isSuccess ||
            createEnterpriseInvitationsByEmail.isError
          ) {
            createEnterpriseInvitationsByEmail.reset();
          }
        }}
        {...form}
      >
        <Controller
          name={inputEmailsFormKey}
          control={form.control}
          rules={{
            required: 'Please enter at least one email address',
            validate: (value) => {
              const hasInvalidEmail =
                value.findIndex((item) => {
                  return validateEmail(item.email) !== true;
                }) !== -1;

              if (hasInvalidEmail) {
                return 'One or more email addresses are invalid.';
              }

              return true;
            },
          }}
          render={({ field, fieldState }) => {
            const mappedValues = field.value.map((item) => item.email);
            const globalError: ValidationError | null = fieldState.error
              ?.message
              ? {
                  message: fieldState.error.message,
                  type: 'error',
                }
              : null;
            const errors = fieldState.error
              ? [globalError, ...validateEmails(mappedValues)]
              : undefined;

            return (
              <InputMultiple
                value={mappedValues}
                errors={errors}
                placeholder="Enter email addresses"
                onValueChange={(value) => {
                  field.onChange(value.map((email) => ({ email })));
                }}
                onBlur={field.onBlur}
              />
            );
          }}
        />

        {createEnterpriseInvitationsByEmail.isError &&
          (emailsSubmittedCount === failedEmails.length ? (
            <NotificationBox
              data-cy="email-invitations-error"
              variant="error"
              className="my-16"
              title="Something went wrong"
            >
              Please check that the addresses are correct and try resending the
              remaining invitations.
            </NotificationBox>
          ) : (
            <NotificationBox
              data-cy="email-invitations-warning"
              variant="warning"
              className="my-16"
              title={
                failedEmails.length === 1 && emailsSubmittedCount === 1
                  ? "Couldn't send invitation"
                  : `Couldn't send ${failedEmails.length} of ${emailsSubmittedCount} invitations`
              }
            >
              Please check that the addresses are correct and try resending the
              remaining invitations.
            </NotificationBox>
          ))}

        {createEnterpriseInvitationsByEmail.isSuccess && (
          <NotificationBox
            data-cy="email-invitations-sent-notification"
            variant="success"
            className="my-16"
            title={`Invitations sent`}
          />
        )}

        <div className="flex justify-center">
          <Button
            data-cy="send-invitations-button"
            type="submit"
            size="large"
            disabled={createEnterpriseInvitationsByEmail.isPending}
            loading={createEnterpriseInvitationsByEmail.isPending}
            analytics={{
              text: 'Send invitation',
              location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
              action: 'send invitation',
            }}
          >
            {numberOfEmails > 1
              ? `Send ${numberOfEmails} invitations`
              : 'Send invitation'}
          </Button>
        </div>
      </Form>
    </div>
  );
};
