import { IS_DEVELOPMENT } from 'src/config';

export function sanitizeRedirectUrl(str?: string) {
  if (!str) return str;

  try {
    const url = new URL(str, window.location.origin);

    url.searchParams.delete('state');
    url.searchParams.delete('code');

    return url.pathname + url.search + url.hash;
  } catch (e) {
    if (IS_DEVELOPMENT) {
      console.error('Unable to sanitize redirect url:', str);
    }
    return '';
  }
}
