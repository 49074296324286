import { createFileRoute, redirect } from '@tanstack/react-router';
import jwtDecode from 'jwt-decode';

import { RmClaims } from 'src/api/queries/user';
import { createPageMetaTitle } from 'src/utils/createPageMetaTitle';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise')({
  meta: () => [{ title: createPageMetaTitle('Enterprise') }],
  async beforeLoad({ context, location }) {
    const auth = await context.auth;
    const accessToken = jwtDecode<RmClaims>(
      await auth.getAccessTokenSilently()
    );

    if (!auth.user) {
      // this is already handled by _auth, but TS wants us to handle it here too.
      throw redirect({
        to: '/login',
        statusCode: 401,
      });
    }

    const shouldSkipRedirect =
      location.pathname !== Route.fullPath &&
      location.pathname !== Route.fullPath + '/';

    if (shouldSkipRedirect) return;

    if (!userHelpers.isOrganizationMember(auth.user)) {
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/enroll',
        replace: true,
      });
    }

    if (userHelpers.hasPermissionReadMembers(accessToken)) {
      throw redirect({
        from: '/enterprise',
        to: '/enterprise/members',
        replace: true,
      });
    }

    throw redirect({
      from: '/enterprise',
      to: '/enterprise/plan',
    });
  },
});
