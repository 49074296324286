import { createFileRoute, redirect } from '@tanstack/react-router';
import jwtDecode from 'jwt-decode';

import { RmClaims } from 'src/api/queries/user';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/enterprise/enroll')({
  async beforeLoad({ context }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw new Error('Unauthorized');
    }

    if (!auth.user.email) {
      throw new Error('Email not found');
    }

    const allowedDomains = [
      'remarkable.no',
      'remarkable.com',
      'inbox.testmail.app',
    ];
    const currentDomain = auth.user.email.split('@')[1];
    const accessToken: RmClaims = jwtDecode(
      await auth.getAccessTokenSilently()
    );

    if (
      allowedDomains.includes(currentDomain) ||
      userHelpers.hasPermissionAccessEnterpriseBeta(accessToken)
    ) {
      return;
    }

    throw redirect({ to: '/', search: { access_denied: 'Unauthorized' } });
  },
});
