import { EventHandler, MouseEvent } from 'react';

export interface ImageSource {
  type: string;
  url: string;
}

interface ImageWithFallbackProps {
  className?: string;
  imgClassName?: string;
  sources: ImageSource[];
  alt?: string;
  onClick?: EventHandler<MouseEvent<HTMLPictureElement>>;
}

export function ImageWithFallback({
  className,
  imgClassName,
  sources,
  alt,
  onClick,
}: ImageWithFallbackProps) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <picture className={className} onClick={onClick}>
      {sources.slice(0, -1).map(({ type, url }) => (
        <source key={url} srcSet={url} type={`image/${type}`} />
      ))}
      <img
        src={sources[sources.length - 1]?.url}
        alt={alt}
        className={imgClassName}
      />
    </picture>
  );
}
