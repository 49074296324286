import { Subscription } from 'src/api/endpoints/storeApi.types';

import { subscriptionHelpers } from './subscriptionHelpers';

export const subscriptionHasNoCard = (subscription?: Subscription | null) => {
  if (subscriptionHelpers.isStripe(subscription)) {
    return (
      !(subscription.paymentInformation?.payment?.type === 'card') ||
      !subscription.paymentInformation?.payment?.details
    );
  }
  return false;
};
