import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  CheckoutStepCompleted,
  PaymentFailed,
  PaymentSucceeded,
  SetupStepCompleted,
} from 'src/ampli';
import { AnalyticsCoupons } from 'src/analytics';
import { tracker } from 'src/analytics/tracker';
import { CHECKOUT_STEPS } from 'src/analytics/trackingTypes';
import { MONTHLY_CONNECT_PRICE_IN_USD_FOR_TRACKING } from 'src/analytics/utils';
import {
  mapCheckoutTypeToRouteType,
  useCheckoutType,
} from 'src/apps/checkout/app/useCheckoutType';
import { useUrlSearchParams } from 'src/hooks/useUrlSearchParams';
import {
  COUNTRIES,
  CountryData,
  DEFAULT_COUNTRY,
  getIsCountrySupported,
} from 'src/utils/countryList';

import { storeApi } from '../endpoints';
import {
  CheckoutCartTaxBody,
  CheckoutType,
  CheckoutTypeParam,
  PaymentInterval,
} from '../endpoints/storeApi.types';
import { cartIdStore } from '../orderIdStore';
import { queryKeys } from '../queries/queryKeys';
import { useCountry } from './country';

export const createQueryCheckoutAllowed = () =>
  queryOptions({
    queryKey: queryKeys.checkout.allowed,
    queryFn: storeApi.getCheckoutAllowed,
    staleTime: Infinity,
  });

export const useCheckoutAllowed = () => {
  return useQuery(createQueryCheckoutAllowed());
};

export const useCheckoutAllowedSuspense = () =>
  useSuspenseQuery(createQueryCheckoutAllowed());

const getTrackingCoupon = (checkoutType: CheckoutType | null) => {
  switch (checkoutType) {
    case 'connectOffer':
      return AnalyticsCoupons.CONNECT_OFFER;
    case 'dmOffer':
      return AnalyticsCoupons.DM_OFFER;
    case 'retailOffer':
      return AnalyticsCoupons.RETAIL_OFFER;
    case 'subscriptionOffer':
      return AnalyticsCoupons.SUBSCRIPTION_OFFER;
    case 'store':
    default:
      return AnalyticsCoupons.NONE;
  }
};

export const useCart = () => {
  const checkoutType = useCheckoutType();
  const selectedCheckoutType = checkoutType ?? 'store';
  const checkoutAllowed = useCheckoutAllowed();
  const queryClient = useQueryClient();
  // We may want to maintain our own country state here in the future if
  // country will be used as a language selector for the page. If that becomes
  // the case, we can have that prepopulate this state so that we don't have
  // to call ipstack multiple times.
  const country = useCountry();
  const isCountrySupported = getIsCountrySupported(
    country.data?.value || null,
    checkoutType
  );

  const setup = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.checkout.setup(selectedCheckoutType),
    queryFn: () =>
      storeApi.setupNewCart({
        type: mapCheckoutTypeToRouteType[selectedCheckoutType],
        country: country.data?.value ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      }),
    enabled:
      !!country.data &&
      !!checkoutType &&
      checkoutAllowed.data?.[checkoutType].allowed === true &&
      isCountrySupported,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!setup.data) return;

    Sentry.addBreadcrumb({
      category: 'checkout',
      data: { cart_id: setup.data.cartId },
      message: 'Cart setup',
    });

    tracker.trackEvent(
      new CheckoutStepCompleted({
        checkout_step: CHECKOUT_STEPS.CHECKOUT_PROCESS_STARTED,
        coupon: getTrackingCoupon(checkoutType),
        products: [
          {
            sku: setup.data.product?.sku,
            name: setup.data.product?.name,
            quantity: 1,
          },
        ],
      })
    );

    queryClient.setQueryData(queryKeys.checkout.details(setup.data.cartId), {
      data: setup.data,
    });
  }, [setup.data, checkoutType, queryClient]);

  const details = useQuery({
    queryKey: queryKeys.checkout.details(
      selectedCheckoutType,
      setup.data?.cartId
    ),
    queryFn: () =>
      storeApi.getCartDetails({
        type: mapCheckoutTypeToRouteType[selectedCheckoutType],
        id: setup.data?.cartId ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      }),
    staleTime: Infinity,
    enabled: !!country.data?.currency && !!setup.data?.cartId,
  });

  const updateCurrency = useMutation({
    mutationKey: queryKeys.checkout.currency,
    mutationFn: (country: CountryData) =>
      storeApi.updateCartDetails({
        type: mapCheckoutTypeToRouteType[checkoutType ?? 'store'],
        country: country.value,
        id: setup.data?.cartId ?? '',
        secret: setup.data?.clientSecret ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      }),
    onMutate(newCountry: CountryData) {
      // optimistic update so that the UI updates immediately
      const previousCountry = country.data;
      country.setCountry(newCountry);

      return { previousCountry };
    },
    onSuccess(response) {
      queryClient.setQueryData(
        queryKeys.checkout.details(selectedCheckoutType, setup.data?.cartId),
        response
      );
    },
    onError(_error, _, context) {
      // revert optimistic update if the request fails
      queryClient.setQueryData(
        queryKeys.checkout.country,
        context?.previousCountry
      );
    },
  });

  const updateTax = useMutation({
    mutationKey: queryKeys.checkout.tax,
    mutationFn: (data: CheckoutCartTaxBody) =>
      storeApi.updateCartDetails({
        type: mapCheckoutTypeToRouteType[checkoutType ?? 'store'],
        country: country.data?.value ?? '',
        id: setup.data?.cartId ?? '',
        taxData: data,
        secret: setup.data?.clientSecret ?? '',
        paymentInterval: PaymentInterval.MONTHLY,
      }),
    onSuccess: (details) => {
      queryClient.setQueryData(
        queryKeys.checkout.details(selectedCheckoutType, setup.data?.cartId),
        details
      );
    },
  });

  const checkout = useMutation({
    mutationKey: queryKeys.checkout.checkoutCart,
    mutationFn: (details: Record<string, unknown>) =>
      storeApi.checkoutCart({
        type: mapCheckoutTypeToRouteType[checkoutType ?? 'store'],
        data: {
          intent_client_secret: setup.data?.clientSecret,
          cart_id: setup.data?.cartId,
          order_id: undefined,
          details: details,
        },
      }),
    onSuccess: (data) => {
      if (!data.cartId)
        throw new Error(
          `No cart id returned from checkout for cart ${
            setup.data?.cartId ?? ''
          }`
        );
      cartIdStore.set(data.cartId);
    },
  });

  // Checking if country is supported, as country can be cached from an instance where it is supported
  useEffect(() => {
    if (!country.data?.value || !checkoutType) return;

    if (!isCountrySupported) {
      const defaultCountry = COUNTRIES.find(
        ({ value }) => value === DEFAULT_COUNTRY
      );
      if (!defaultCountry) {
        return;
      }
      // Will also update country
      updateCurrency.mutate(defaultCountry);
    }
  }, [country.data?.value, checkoutType, isCountrySupported]);

  return {
    setup,
    details,
    country,
    setCountry: (country: CountryData) => {
      updateCurrency.mutate(country);
    },
    updateCurrency,
    updateTax,
    checkout,
    checkoutType,
  };
};

export const useConfirmPayment = () => {
  const queryClient = useQueryClient();
  const checkoutType = useCheckoutType();
  const [
    {
      setup_intent_client_secret: setupSecret,
      payment_intent_client_secret: paymentSecret,
    },
  ] = useUrlSearchParams();

  return useMutation({
    mutationFn: (vars: { type: CheckoutTypeParam }) => {
      const cartId = cartIdStore.get();
      if (!cartId) throw new Error('[ConfirmPayment]: No cart id');

      if (!setupSecret && !paymentSecret)
        throw new Error('[ConfirmPayment]: No client secret');

      return storeApi.postCheckoutPaymentVerifyIntent({
        type: vars.type,
        payload: {
          order_id: cartId,
          paymentInterval: PaymentInterval.MONTHLY,
          setup_intent_client_secret: setupSecret ?? undefined,
          payment_intent_client_secret: paymentSecret ?? undefined,
        },
      });
    },
    onSuccess: ({ data }) => {
      tracker.trackEvent(
        new PaymentSucceeded({
          order_id: data.orderId,
          currency: data.currency,
          products: [
            {
              sku: { value: data.product?.sku },
              name: { value: data.product?.name },
              quantity: { value: 1 },
            },
          ],
          revenue: MONTHLY_CONNECT_PRICE_IN_USD_FOR_TRACKING.toString(),
          // We want the same revenue regardless of the currency, so we use USD
          // TODO: Look into getting the price from the API instead of hardcoding it
          coupon: getTrackingCoupon(checkoutType),
        })
      );
      tracker.trackEvent(
        new SetupStepCompleted({
          step_name: 'Activate your free Connect',
          step_number: 3,
        })
      );

      cartIdStore.remove();
      void queryClient.invalidateQueries({
        queryKey: queryKeys.checkoutBaseKey,
      });
      void queryClient.invalidateQueries({
        queryKey: queryKeys.dev.stripeTestClock,
      }); // QoL
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
    onError: () => {
      cartIdStore.remove();
      tracker.trackEvent(new PaymentFailed({}));
    },
    mutationKey: queryKeys.checkout.confirmPayment,
  });
};
