import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { CaretDown, Check, X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseFinalize,
} from 'src/api/queries/enterpriseEnrollment';
import { useAuth0Claims } from 'src/api/queries/user';
import { Button, NotificationBox, Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

export function ConfirmSetupPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const finalizeEnrollment = useEnterpriseFinalize();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const { user } = useAuth0();
  const claims = useAuth0Claims();

  const isLoggedInToSSO = !!claims.data?.org_id;

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Add users
            </Typography>

            <CancelSetupModal />
          </div>

          <div className="flex justify-between gap-24 border-y py-16">
            <Typography variant="body-md-regular">
              Owner: {user?.email ?? 'No email'}
            </Typography>

            {isLoggedInToSSO ? (
              <div className="flex items-center gap-8 text-pen-blue">
                <Check size={24} />
                <span>Verified</span>
              </div>
            ) : (
              <div className="flex items-center gap-8 text-neutral-dark-1">
                <X size={24} />
                <span>Not verified</span>
              </div>
            )}
          </div>

          <Typography variant="body-md-regular">
            Add existing users to your identity provider (IdP) to ensure a
            smooth onboarding process.
          </Typography>

          <Typography variant="body-md-regular">
            When you complete this step, an email will be sent to all existing
            users, so they can either migrate their account or change their
            email address.
          </Typography>

          <div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                switch (e.key) {
                  case 'Enter':
                  case ' ': {
                    e.preventDefault();
                    setIsExpanded((s) => !s);
                  }
                }
              }}
              onClick={() => {
                setIsExpanded((s) => !s);
              }}
              className="flex items-center justify-between gap-16 rounded transition-all hover:bg-neutral-light-2 focus:bg-neutral-light-2"
            >
              <Typography
                variant="body-lg-bold"
                className="select-none font-medium"
              >
                What happens to affected users?
              </Typography>

              <CaretDown size={24} className={isExpanded ? 'rotate-180' : ''} />
            </div>

            <div
              className={clsx(
                'flex flex-col gap-16 overflow-hidden transition-all',
                isExpanded ? 'h-[calc-size(auto)]' : 'h-0'
              )}
            >
              <Typography variant="body-sm-regular">
                An{' '}
                <b className="font-semibold">
                  email is sent out to all existing users
                </b>{' '}
                once setup is complete. All accounts with your domain email
                addresses will receive this email, whether they&apos;ve been
                added to your identity provider (IdP) or not. Users have these
                options:
              </Typography>

              <ol className="list-decimal pl-20">
                <li>
                  <Typography variant="body-sm-regular">
                    If users are added to your identity provider, they can:
                  </Typography>

                  <ul className="list-disc pl-20">
                    <li>
                      <Typography variant="body-sm-regular">
                        Accept and migrate their account, or
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body-sm-regular">
                        Change the email address on their existing account.
                        Their new email address can&apos;t be connected to your
                        domain. A new enterprise account is still created.
                      </Typography>
                    </li>
                  </ul>
                </li>

                <li className="mt-24">
                  <Typography variant="body-sm-regular">
                    If users haven&apos;t been added to your identity provider,
                    they&apos;ll need to change the email address on their
                    account. Their new email address can&apos;t be connected to
                    your domain.
                  </Typography>
                </li>
              </ol>

              <Typography variant="body-sm-regular">
                To avoid having users change their email addresses,{' '}
                <b className="font-semibold">
                  ensure that all existing users have been added to your
                  identity provider
                </b>{' '}
                before completing setup.
              </Typography>

              <Typography variant="body-sm-regular">
                If you need to add users to your identity provider, you can
                safely leave this page and continue later.
              </Typography>
            </div>
          </div>

          <div className="flex justify-center gap-24">
            <Button as="a" variant="secondary" to={URLS.ENTERPRISE_VERIFY_SAML}>
              Previous
            </Button>
            <Button
              variant="primary"
              disabled={finalizeEnrollment.isPending}
              loading={finalizeEnrollment.isPending}
              onClick={() => {
                if (typeof enrollmentStatus.data?.connectionId !== 'string')
                  return;

                finalizeEnrollment.mutate(undefined, {
                  onSuccess() {
                    toast.success('Enterprise enrollment complete!');
                    // We need to update accesstoken in order to get new permissions so navigating to fleet via login
                    return navigate({
                      to: '/login',
                      search: {
                        prompt: 'none',
                        redirect: '/enterprise',
                      },
                    });
                  },
                });
              }}
            >
              Complete
            </Button>
          </div>

          {finalizeEnrollment.isError && (
            <NotificationBox
              variant="error"
              title={finalizeEnrollment.error.name || 'Something went wrong'}
            >
              {finalizeEnrollment.error.message ||
                'Please try again later or contact support if the problem persists.'}
            </NotificationBox>
          )}
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
