import { HTMLAttributes, ReactNode } from 'react';

import { FleetIntegration } from 'src/api/endpoints/cloudEnterprise.types';
import DropboxSvg from 'src/assets/icons/dropbox.svg';
import GoogleDriveSvg from 'src/assets/icons/googledrive.svg';
import OneDriveSvg from 'src/assets/icons/onedrive.svg';

export function IntegrationIcon({
  integration,
  fallback,
  ...props
}: HTMLAttributes<HTMLImageElement> & {
  integration?: FleetIntegration;
  fallback: ReactNode;
}) {
  const name = integration?.provider?.toLowerCase() ?? '';

  if (name.includes('google')) {
    return <img src={GoogleDriveSvg} alt="Google Drive" {...props} />;
  }

  if (name.includes('dropbox')) {
    return <img src={DropboxSvg} alt="Dropbox" {...props} />;
  }

  if (name.includes('onedrive')) {
    return <img src={OneDriveSvg} alt="OneDrive" {...props} />;
  }

  return <>{fallback}</>;
}
