import {
  Desktop,
  DeviceMobile,
  GoogleChromeLogo,
  IconWeight,
  Planet,
} from '@phosphor-icons/react';
import { RM1, RM2, RMpp } from '@remarkable/ark-icons';

import { FleetDevice } from 'src/api/endpoints/cloudEnterprise.types';
import msPowerPoint from 'src/assets/icons/msPowerPoint.svg';
import msWord from 'src/assets/icons/msWord.svg';

function getIconFromSerialNumber(serialNumber?: string) {
  if (!serialNumber) {
    return Planet;
  }
  if (serialNumber.startsWith('RM10')) {
    return RM1;
  }
  if (serialNumber.startsWith('RM11')) {
    return RM2;
  }
  if (serialNumber.startsWith('RM02')) {
    return RMpp;
  }

  return RM2;
}

export const DeviceIcon = (props: {
  device: FleetDevice;
  size?: number;
  className?: string;
  weight?: IconWeight;
}) => {
  const size = props.size ?? 24;

  switch (props.device.type) {
    case 'desktop-linux':
    case 'desktop-macos':
    case 'desktop-windows':
      return (
        <Desktop
          size={size}
          weight={props.weight}
          className={props.className}
        />
      );
    case 'mobile-android':
    case 'mobile-ios':
      return (
        <DeviceMobile
          size={size}
          weight={props.weight}
          className={props.className}
        />
      );
    case 'browser-chrome':
      return (
        <GoogleChromeLogo
          size={size}
          weight={props.weight}
          className={props.className}
        />
      );
    case 'remarkable': {
      const Icon = getIconFromSerialNumber(props.device.serialNumber);

      return (
        <Icon size={size} weight={props.weight} className={props.className} />
      );
    }
    default:
      if (props.device.type?.includes('word')) {
        return (
          <img
            className={props.className}
            height={size}
            width={size}
            src={msWord}
            alt="Word extension"
          ></img>
        );
      }
      if (props.device.type?.includes('powerpoint')) {
        return (
          <img
            className={props.className}
            height={size}
            width={size}
            src={msPowerPoint}
            alt="PowerPoint extension"
          ></img>
        );
      }

      if (props.device.type?.includes('desktop')) {
        return (
          <Desktop
            size={size}
            weight={props.weight}
            className={props.className}
          />
        );
      }

      if (props.device.type?.includes('mobile')) {
        return (
          <DeviceMobile
            size={size}
            weight={props.weight}
            className={props.className}
          />
        );
      }

      if (props.device.type?.includes('extension')) {
        return (
          <Planet
            size={size}
            weight={props.weight}
            className={props.className}
          />
        );
      }

      if (
        props.device.type?.includes('remarkable') ||
        !!props.device.serialNumber
      ) {
        const Icon = getIconFromSerialNumber(props.device.serialNumber);

        return (
          <Icon size={size} weight={props.weight} className={props.className} />
        );
      }

      return (
        <Planet size={size} weight={props.weight} className={props.className} />
      );
  }
};
