import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { CheckoutType } from 'src/api/endpoints/storeApi.types';
import { getCheckoutUrl } from 'src/apps/checkout/app/useCheckoutType';
import heroOffer from 'src/assets/img/hero-offer.webp';
import { Button } from 'src/components';
import { ImageWithFallback } from 'src/components/ImageWithFallback';
import { StackedSheet } from 'src/components/StackedSheet';
import { SKU } from 'src/utils/skus';

import { Price } from '../Price';
import { ExpirationText } from './ExpirationText';

export const TimeToSync = ({
  type,
  text,
  buttonText,
  title,
  analytics,
  expirationDate,
}: {
  type: CheckoutType;
  text: string;
  buttonText: string;
  title: string;
  analytics?: {
    text: string;
    location: string;
    action: string;
  };
  expirationDate: string | null;
}) => {
  return (
    <StackedSheet dataCy="time-to-sync-landing">
      <div className=" flex flex-col  lm:flex-row">
        <div className="lm:3/5 mb-32 flex flex-1 flex-col gap-32 text-left lm:my-auto lm:pr-64">
          <div>
            <ExpirationText expirationDate={expirationDate} />
            <Typography variant="heading-2xl">{title}</Typography>
          </div>

          <Typography variant="body-md-regular">{text}</Typography>

          <Button
            as="a"
            variant="primary"
            to={getCheckoutUrl(type)}
            className="w-full lm:w-fit"
            data-cy="landing-next-step"
            analytics={analytics}
          >
            <span data-cy={`${type}-activate-button`}>{buttonText}</span>
            <ArrowRight size={32} />
          </Button>

          <Typography variant="interface-sm-regular">
            <span>
              Connect costs <Price sku={SKU.connectMonthly} />
              /mo. <b>Cancel anytime.</b>
            </span>
          </Typography>
        </div>

        <div className="w-full flex-1 lm:w-2/5">
          <ImageWithFallback
            className="justify-center lm:flex"
            imgClassName="lm:max-h-full py-8 max-h-[200px] w-full object-cover"
            sources={[
              {
                type: 'webp',
                url: heroOffer,
              },
            ]}
          />
        </div>
      </div>
    </StackedSheet>
  );
};
