import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { InvitationSent } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { userHelpers } from 'src/utils/userHelpers';

import { cloudEnterpriseApi } from '../endpoints';
import { queryKeys } from './queryKeys';
import { useAccessTokenClaims } from './user';

export const queryEnterpriseInvitations = () =>
  queryOptions({
    queryKey: queryKeys.enterpriseInvitations.all,
    queryFn: () => cloudEnterpriseApi.getInvitations(),
  });

export function useEnterpriseInvitations() {
  const accessToken = useAccessTokenClaims();
  return useQuery({
    ...queryEnterpriseInvitations(),
    enabled: userHelpers.hasPermissionReadInvitations(accessToken.data),
  });
}

interface CreateEmailsResult {
  successful: {
    email: string;
    response: Awaited<ReturnType<typeof cloudEnterpriseApi.createInvitation>>;
  }[];
  unsuccessful: {
    email: string;
    error: unknown;
  }[];
}

class CreateEmailsError extends Error {
  constructor(public result: CreateEmailsResult) {
    super('An error occurred while sending out invitations.');
  }
}

export function useCreateEnterpriseInvitations() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseInvitations.create,
    mutationFn: async ({ emails }: { emails: string[] }) => {
      // Fetch all the emails in parallel and wait for them to settle by catching
      // the errors. This way we can return a list of both successful and
      // unsuccessful emails.
      const response = await Promise.all(
        emails.map((email) => {
          return cloudEnterpriseApi
            .createInvitation({
              email,
            })
            .then((response) => ({
              type: 'fulfilled' as const,
              email,
              response,
            }))
            .catch((error: unknown) => ({
              type: 'rejected' as const,
              email,
              error,
            }));
        })
      );

      const sortedResponse = response.reduce<CreateEmailsResult>(
        (acc, result) => {
          if (result.type === 'fulfilled') {
            acc.successful.push(result);
          } else {
            acc.unsuccessful.push(result);
          }

          return acc;
        },
        {
          successful: [],
          unsuccessful: [],
        }
      );

      if (sortedResponse.unsuccessful.length > 0) {
        throw new CreateEmailsError(sortedResponse);
      }

      return sortedResponse.successful;
    },
    onSuccess: (_, variables) => {
      tracker.trackEvent(
        new InvitationSent({
          number_of_invitations: variables.emails.length,
          invitation_type: 'enterprise',
        })
      );
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseInvitations.all,
      });
    },
  });
}
