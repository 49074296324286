import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';

import { useFleetMembers } from 'src/api/queries/fleet';
import { FlexDivider, Sheet } from 'src/components';

export function Overview() {
  const members = useFleetMembers();

  const numberOfMembers = members.data?.users?.length ?? 0;
  const numberOfDevices = useMemo(() => {
    if (!members.data?.users) {
      return 0;
    }

    return (
      members.data.users
        .map((u) => u.devices?.length)
        .reduce((acc, current) => (acc ?? 0) + (current ?? 0), 0) ?? 0
    );
  }, [members.data?.users]);

  return (
    <Sheet className="ls:flex-row">
      <div className="flex flex-1">
        <DashboardNumber
          dataCy="members-count"
          value={numberOfMembers}
          label="Total members"
        />

        <FlexDivider />

        <DashboardNumber
          dataCy="devices-count"
          value={numberOfDevices}
          label="Total devices"
        />
      </div>

      <div className="flex flex-1">
        <FlexDivider className="hidden ls:flex" />

        <DashboardNumber
          label="Seats on next billing"
          value={numberOfMembers}
        />
        <FlexDivider />

        <DashboardNumber value="-" label="Next billing" />
      </div>
    </Sheet>
  );
}

const DashboardNumber = ({
  value,
  label,
  dataCy = 'dashboard-number',
}: {
  value: string | number;
  label: string;
  dataCy?: string;
}) => (
  <div
    data-cy={dataCy}
    className="flex flex-1 flex-col items-center justify-center pb-8 text-center lm:py-16"
  >
    <span data-cy="value" className="body-lg-bold ls:body-2xl-bold">
      {value}
    </span>
    <Typography
      variant="interface-sm-regular"
      className="px-8 font-[300] text-gray-600"
    >
      {label}
    </Typography>
  </div>
);
