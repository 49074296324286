import { ReactNode, useEffect } from 'react';

import { Check, Copy } from '@phosphor-icons/react';
import toast from 'react-hot-toast';

import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';

import { Button } from './Button';

export function CopyButton({
  value,
  children,
  className,
  variant = 'tertiary',
  size,
  disabled,
}: {
  value?: string;
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-neutral';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  disabled?: boolean;
}) {
  const copyToClipboard = useCopyToClipboard();

  useEffect(() => {
    if (copyToClipboard.isSuccess) {
      const id = setTimeout(() => {
        copyToClipboard.reset();
      }, 2000);

      return () => clearTimeout(id);
    }
  }, [copyToClipboard.isSuccess]);

  return (
    <Button
      className={className}
      variant={variant}
      size={size}
      disabled={!value || disabled || copyToClipboard.isPending}
      loading={copyToClipboard.isPending}
      onClick={() => {
        copyToClipboard.mutate(value, {
          onError() {
            toast.error('Failed to copy to clipboard');
          },
        });
      }}
    >
      <span className="truncate">{children ?? 'Copy'}</span>
      {copyToClipboard.isSuccess ? (
        <Check className="shrink-0 animate-fade-in-up-short" />
      ) : (
        <Copy className="shrink-0 animate-fade-in-up-short" />
      )}
    </Button>
  );
}
