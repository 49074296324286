import { HTMLAttributes } from 'react';

import { ClockCountdown } from '@phosphor-icons/react';
import { Check, XCircle } from 'phosphor-react';

import {
  Subscription,
  SubscriptionStatus,
} from 'src/api/endpoints/storeApi.types';
import { Tag } from 'src/components';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

export const StatusTag = (
  props: HTMLAttributes<HTMLDivElement> & {
    status?: SubscriptionStatus;
    subscription?: Subscription;
  }
) => {
  const status =
    props.status ?? subscriptionHelpers.getStatus(props.subscription);

  if (!status) {
    return null;
  }

  switch (status) {
    case 'Active':
      return (
        <Tag variant="success" className={props.className}>
          <Check /> Active
        </Tag>
      );
    case 'Cancelled':
      return (
        <Tag variant="error" className={props.className}>
          <XCircle /> Cancelled
        </Tag>
      );
    case 'Deactivated':
      return (
        <Tag className={props.className}>
          <ClockCountdown /> On hold
        </Tag>
      );
    default:
      return null;
  }
};
