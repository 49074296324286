import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { useEnterprisePermissions } from 'src/api/queries/user';
import { NavTab } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute(
  '/_auth/_layout/enterprise/_with-org/_layout'
)({
  component: () => {
    const auth = useAuth0();
    const permissions = useEnterprisePermissions();

    if (!auth.user) {
      throw redirect({
        to: '/login',
        search: {
          redirect: window.location.pathname + window.location.search,
        },
      });
    }

    const orgName =
      userHelpers.getOrganizationDisplayName(auth.user) ?? 'Enterprise';

    return (
      <AppPage.Container className="max-w-l2xl">
        <AppPage.Header
          title={orgName}
          subtitle={
            permissions.data?.readMembers
              ? 'Manage team members, device settings, and billing from one account.'
              : null
          }
        />

        {permissions.data?.readMembers && (
          <AppPage.NavBar className="max-w-l2xl lm:px-32">
            <NavTab to="/enterprise/members">Overview</NavTab>
            <NavTab to="/enterprise/plan">Plan</NavTab>
          </AppPage.NavBar>
        )}

        <Outlet />
      </AppPage.Container>
    );
  },
});
