import { FleetIntegration } from 'src/api/endpoints/cloudEnterprise.types';

export function getIntegrationName(integration: FleetIntegration) {
  const name = integration.provider?.toLowerCase() ?? '';

  if (name.includes('googledrive')) {
    return 'Google Drive';
  }

  if (name.includes('dropbox')) {
    return 'Dropbox';
  }

  if (name.includes('onedrive')) {
    return 'One Drive';
  }

  return 'Unknown integration';
}
