import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { HTTPError } from 'src/api/createApiClient.types';
import { InvitationNotFound } from 'src/apps/invitations/components/InvitationNotFound';
import { InvitationConnectWithSeatsMember } from 'src/apps/invitations/pages/InvitationConnectWithSeatsMember';
import { SomethingWentWrong } from 'src/components';

const invitationSearchSchema = z.object({
  token: z.string().catch(''),
});

type InvitationSearch = z.infer<typeof invitationSearchSchema>;

export const Route = createFileRoute('/_auth/_layout/invitation/seat')({
  component: () => <InvitationConnectWithSeatsMember />,
  validateSearch: (search): InvitationSearch =>
    invitationSearchSchema.parse(search),
  wrapInSuspense: true,
  errorComponent: ({ error }) => {
    if (error instanceof HTTPError && error.response.status === 404) {
      return <InvitationNotFound />;
    }
    return <SomethingWentWrong error={error} />;
  },
});
