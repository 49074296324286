import { Typography } from '@remarkable/ark-web';

import { useEnterprisePermissions } from 'src/api/queries/user';
import { valueProps } from 'src/utils/valueProps';

import { Divider } from './Divider';
import { StatusTag } from './StatusTag';

export function EnterpriseSubscriptionCard() {
  const permissions = useEnterprisePermissions();
  return (
    <div>
      <div
        data-cy="connect-for-business-card"
        className="flex flex-col items-start gap-8 lm:flex-row lm:justify-between"
      >
        <div>
          <Typography variant="heading-subtle-2xl">
            Enterprise access
          </Typography>
        </div>

        <StatusTag status="Active" />
      </div>

      <Divider className="my-24" />

      <div
        data-cy="connect-offer-perks"
        className="flex w-full flex-col gap-16"
      >
        {permissions.data?.readMembers ? (
          <Typography variant="body-lg-regular">
            Manage multiple subscriptions from one account. Your team gets
            access to the following features and benefits:
          </Typography>
        ) : (
          <Typography variant="body-lg-regular">
            Here&apos;s what your subscription includes:
          </Typography>
        )}

        <ul className="grid gap-8 lm:grid-cols-2">
          {Object.values(valueProps.enterprise).map((valueProp) => (
            <li key={valueProp.id} className="flex items-center gap-8">
              <div className="flex items-center gap-8">
                <valueProp.icon className="text-pen-blue" size={20} />
                <Typography variant="body-md-regular">
                  {valueProp.title}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
