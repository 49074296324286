import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { SeatUpdated } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';

import { getCurrentMember } from '../../utils/getCurrentMember';
import { storeApi } from '../endpoints';
import {
  getStripeSubscription,
  transformPaymentIntervals,
} from '../transformers';
import { queryKeys } from './queryKeys';

export const createQuerySubscriptions = () =>
  queryOptions({
    queryKey: queryKeys.subscriptions.all,
    queryFn: () => storeApi.getSubscription(),
    staleTime: 10_000,
  });

export const useSubscription = () => useQuery(createQuerySubscriptions());

export const useSubscriptionSuspense = () =>
  useSuspenseQuery(createQuerySubscriptions());

export const useStripeSubscription = () =>
  useQuery({
    ...createQuerySubscriptions(),
    select: getStripeSubscription,
  });

export const useStripeSubscriptionSuspense = () =>
  useSuspenseQuery({
    ...createQuerySubscriptions(),
    select: getStripeSubscription,
  });

interface UseSubscriptionMembersOptions<TSelect = unknown> {
  enabled?: boolean;
  select?: (
    members: Awaited<ReturnType<typeof storeApi.getSubscriptionMembers>>
  ) => TSelect;
}

export const useSubscriptionMembers = <
  TSelect = Awaited<ReturnType<typeof storeApi.getSubscriptionMembers>>
>(
  options?: UseSubscriptionMembersOptions<TSelect>
) => {
  const subscription = useStripeSubscription();
  const subscriptionId = subscription.data?.id ?? '';

  return useQuery({
    queryKey: queryKeys.subscriptions.members(subscriptionId),
    queryFn: () => storeApi.getSubscriptionMembers(subscriptionId),
    enabled: !!subscription.data?.id && options?.enabled !== false,
    staleTime: 10_000,
    select: options?.select,
  });
};

export const useCurrentMember = () =>
  useSubscriptionMembers({ select: getCurrentMember });

export const usePaymentIntervals = () =>
  useQuery({
    ...createQuerySubscriptions(),
    select: transformPaymentIntervals,
  });

export const useUpdateSubscriptionToSeats = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (vars: { subscriptionId?: string }) => {
      if (!vars.subscriptionId) {
        throw new Error('No subscription id available');
      }

      return storeApi.updateSubscriptionManagementTypeToSeats({
        subscriptionId: vars.subscriptionId,
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};

export const useCreateSeatForMember = ({ memberId }: { memberId: string }) => {
  const subscription = useStripeSubscription();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.subscriptions.createSeatForMember(memberId),
    mutationFn: ({ memberId }: { memberId: string }) => {
      if (!subscription.data?.id)
        throw new Error('No subscription id, could not request seat creation');

      return storeApi.createSeatForMember({
        subscriptionId: subscription.data.id,
        memberId,
      });
    },
    async onSuccess() {
      if (!subscription.data?.id) {
        // This should never happen, but we still know that the seat was added
        toast.success('Seat activated');
        throw new Error('No subscription id, could not invalidate query');
      }

      // Promise.all for parallel invalidation
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.members(subscription.data.id),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.all,
        }),
      ]);

      toast.success('Seat activated');
      tracker.trackEvent(new SeatUpdated({ action: 'activated' }));
    },
    onError() {
      toast.error('Seat activation failed');
    },
  });
};

export const useRemoveSeatForMember = ({ memberId }: { memberId: string }) => {
  const subscription = useStripeSubscription();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.subscriptions.removeSeatForMember(memberId),
    mutationFn: ({ memberId }: { memberId: string }) => {
      if (!subscription.data?.id)
        throw new Error('No subscription id, could not request seat removal');

      return storeApi.removeSeatForMember({
        subscriptionId: subscription.data.id,
        memberId,
      });
    },
    async onSuccess() {
      if (!subscription.data?.id) {
        // This should never happen, but we still know that the seat was removed
        toast.success('Seat deactivated');
        throw new Error('No subscription id, could not invalidate query');
      }

      // Promise.all for parallel invalidation
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.members(subscription.data.id),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.all,
        }),
      ]);

      // Toast after invalidation
      toast.success('Seat deactivated');
      tracker.trackEvent(new SeatUpdated({ action: 'deactivated' }));
    },
    onError() {
      toast.error('Seat deactivation failed');
    },
  });
};

export const useChangePaymentMethodSetupIntent = () =>
  useMutation({
    mutationKey: queryKeys.subscriptions.createSetupIntent,
    mutationFn: storeApi.changePaymentMethodSetupIntent,
  });

export const useChangePaymentMethodVerifySetupIntent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.subscriptions.verifySetupIntent,
    mutationFn: storeApi.changePaymentMethodVerifySetupIntent,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.all,
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.settings.paymentHistory,
        }),
      ]);
    },
  });
};

export const useUpdateMemberRole = () => {
  const queryClient = useQueryClient();
  const subscription = useStripeSubscription();

  return useMutation({
    mutationKey: queryKeys.subscriptions.updateMemberRole,
    mutationFn: (args: { newRole: string; memberId: string }) => {
      if (!subscription.data?.id) {
        throw new Error('No subscription id, could not update member role');
      }
      return storeApi.updateSubscriptionMemberRole({
        subscriptionId: subscription.data.id,
        subscriptionRoleId: args.memberId,
        newRole: args.newRole,
      });
    },
    onSuccess() {
      if (!subscription.data?.id) {
        throw new Error('No subscription id, could not invalidate query');
      }

      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.members(subscription.data.id),
      });
    },
  });
};
