import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { HTTPError } from 'src/api/createApiClient.types';
import {
  useAcceptSeatInvitation,
  useDeclineSeatInvitation,
  useGetSeatInvitation,
} from 'src/api/queries';
import imageC4bMemberJpg from 'src/assets/img/c4b-member-invite.jpg';
import imageC4bMemberWebp from 'src/assets/img/c4b-member-invite.webp';
import {
  Button,
  Divider,
  FaqSection,
  Feedback,
  ImageWithFallback,
  Link,
  NotificationBox,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { FAQ } from 'src/constants/FAQ';
import { useNotifications } from 'src/hooks/useNotifications';
import { formatAmount } from 'src/utils/productUtils';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';
import { valueProps } from 'src/utils/valueProps';

import { AlreadyHaveFreeConnect } from '../components/AlreadyHaveFreeAccess';
import { AlreadyInSeatManagement } from '../components/AlreadyInSeatManagement';
import { InvitationDeclined } from '../components/InvitationDeclined';
import { InvitationExpired } from '../components/InvitationExpired';
import { InvitationNotFound } from '../components/InvitationNotFound';
import { InviterIsPaused } from '../components/InviterIsPaused';

const routeApi = getRouteApi('/_auth/_layout/invitation/seat');

export const InvitationConnectWithSeatsMember = () => {
  const navigate = routeApi.useNavigate();
  const { token } = routeApi.useSearch();
  const invitationStatus = useGetSeatInvitation(token);
  const notifications = useNotifications();

  const acceptInvitation = useAcceptSeatInvitation({
    onSuccess: () => {
      void navigate({ to: '/subscriptions' });
      notifications.set({ id: 'JOINED_CONNECT_SEATS_AS_MEMBER' });
    },
  });

  const declineInvitation = useDeclineSeatInvitation();

  if (!token) {
    void navigate({ to: '/subscriptions' });
    return null;
  }

  if (invitationStatus.isPending) {
    return <Spinner />;
  }

  if (declineInvitation.isSuccess) {
    return <InvitationDeclined />;
  }

  if (invitationStatus.isError) {
    if (
      invitationStatus.error instanceof HTTPError &&
      invitationStatus.error.response.status === 404
    ) {
      return <InvitationNotFound />;
    }
    return <SomethingWentWrong />;
  }

  if (invitationStatus.data?.canAccept === false) {
    switch (invitationStatus.data.reason) {
      case 'INVITATION_TOKEN_IS_EXPIRED':
        return <InvitationExpired />;
      case 'CUSTOMER_HAS_FREE_CONNECT':
        return <AlreadyHaveFreeConnect />;
      case 'CUSTOMER_ALREADY_HAS_ROLE_IN_ANOTHER_SEAT_MANAGED_SUBSCRIPTION':
        return <AlreadyInSeatManagement />;
      case 'INVITING_SUBSCRIPTION_PAUSED':
        return <InviterIsPaused />;
    }
  }

  const isOverDue =
    invitationStatus.data?.canAccept === false &&
    invitationStatus.data.reason === 'CUSTOMER_SUBSCRIPTION_IS_PAST_DUE';

  const isAcceptable = invitationStatus.data?.canAccept === true;

  const subscription = invitationStatus.data?.canAccept
    ? invitationStatus.data.subscription
    : null;

  const refund =
    invitationStatus.data?.canAccept === true &&
    invitationStatus.data.subscription?.refund;

  const companyName =
    invitationStatus.data?.canAccept && invitationStatus.data.companyName;

  return (
    <AppPage.Container data-cy="connect-for-business-member-invitation-page">
      <AppPage.Header
        className="max-w-ll"
        title={
          <>
            <Typography variant="heading-xs-italic">
              You&apos;ve been invited to join
            </Typography>
            <Typography
              variant="responsive-heading-md"
              className="mb-16 text-center"
            >
              Connect
            </Typography>
          </>
        }
      >
        {subscription?.trial?.mode === 'partial' ? (
          <Typography variant="body-lg-regular">
            When you join you&apos;ll keep access to subscription benefits
            without any changes to your documents or account. Paid for by{' '}
            {companyName || 'your company'} once your free trial ends.
          </Typography>
        ) : subscription?.trial?.mode === 'full' ? (
          <Typography variant="body-lg-regular">
            When you join you&apos;ll get instant access to subscription
            benefits without any changes to your documents or account. Paid for
            by {companyName || 'your company'} once your free trial ends.
          </Typography>
        ) : subscription ? (
          <Typography variant="body-lg-regular">
            When you join you&apos;ll keep access to subscription benefits
            without any changes to your documents or account. Paid for by{' '}
            {companyName || 'your company'}.
          </Typography>
        ) : (
          <Typography variant="body-lg-regular">
            When you join you&apos;ll get instant access to subscription
            benefits without any changes to your documents or account. Paid for
            by {companyName || 'your company'}.
          </Typography>
        )}

        {isOverDue && (
          <NotificationBox
            className="my-32"
            variant="error"
            title="Your current subscription is overdue"
            data-cy="subscription-over-due-notification"
          >
            <span className="mt-8">
              There was a problem with your last subscription payment. To join
              Connect, please{' '}
              <Link to={URLS.SUBSCRIPTION} inline>
                update or change your payment details
              </Link>{' '}
              to pay the overdue charge.
            </span>
          </NotificationBox>
        )}

        {!isAcceptable && !isOverDue && (
          <NotificationBox
            className="my-32"
            variant="error"
            title="Unable to accept invitation"
            data-cy="unable-to-accept-notification"
          >
            <span className="mt-8">
              You can&apos;t currently accept the invitation. Please contact
              support if the problem persists.
            </span>
          </NotificationBox>
        )}

        {acceptInvitation.isError ? (
          <NotificationBox
            className="my-32"
            variant="error"
            title="Something went wrong when accepting the invitation"
            data-cy="accept-invitation-error"
          >
            Please try again later or contact support if the problem persists.
          </NotificationBox>
        ) : declineInvitation.isError ? (
          <NotificationBox
            className="my-32"
            variant="error"
            title="Something went wrong when declining the invitation"
            data-cy="decline-invitation-error"
          >
            Please try again later or contact support if the problem persists.
          </NotificationBox>
        ) : null}

        <div className="mt-24 flex flex-col-reverse justify-center gap-x-32 gap-y-16 ls:flex-row">
          <Button
            onClick={() => {
              declineInvitation.reset();
              acceptInvitation.mutate({ invitationTokenUUID: token });
            }}
            className="w-full ls:w-fit"
            loading={acceptInvitation.isPending}
            disabled={declineInvitation.isPending || !isAcceptable}
            data-cy="accept-invitation-button"
          >
            Accept invitation
          </Button>
        </div>

        <Typography variant="body-sm-regular" className="my-24 text-center">
          By accepting the invitation you agree to our{' '}
          <Link inline to={SUPPORT_URL.LEGAL_OVERVIEW}>
            terms and conditions
          </Link>
          .
          {refund && (
            <span data-cy="refund-message">
              &nbsp;If you join this Connect subscription, your current
              subscription will end, and you&apos;ll be refunded{' '}
              {formatAmount(refund.amount, refund.currency)} for the rest of
              your current subscription period.
            </span>
          )}
        </Typography>
      </AppPage.Header>

      <AppPage.Content className="max-w-ll">
        <Divider title="Subscription benefits" className="my-32" />
        <Typography variant="body-lg-regular" className="mb-32 text-center">
          Access features that enable seamless note-taking across devices.
        </Typography>
        <ImageWithFallback
          sources={[
            {
              type: 'image/webp',
              url: imageC4bMemberWebp,
            },
            {
              type: 'image/jpeg',
              url: imageC4bMemberJpg,
            },
          ]}
          alt="Connect member invitation"
        />

        <ul className="mx-auto my-32 flex max-w-[340px] flex-wrap justify-center gap-24 md:max-w-full">
          {Object.values(valueProps.connectWithSeats).map((valueProp) => (
            <li
              key={valueProp.id}
              className="flex w-[120px] flex-col items-center justify-start"
            >
              <valueProp.icon
                size={40}
                weight="light"
                className="text-pen-blue"
              />
              <Typography
                variant="body-sm-regular"
                className="mt-12 text-center"
              >
                {valueProp.title}
              </Typography>
            </li>
          ))}
        </ul>

        <Feedback
          feedbackTargetId="c4b-member-invitation-page-v1"
          title="Did you find this page helpful?"
        />

        <FaqSection
          className="mt-128"
          componentLocation={
            ComponentLocations.CONNECT_WITH_SEATS.INVITATION_MEMBER_PAGE
          }
          sections={[
            FAQ.connectWithSeats.whatHappensToMyFilesIfIJoin(),
            FAQ.connectWithSeats.canOtherUsersGetAccessToMyDocuments(),
            FAQ.connectWithSeats.myPaperTabletIsPairedWithPersonalAccount(),
            FAQ.connectWithSeats.whatHappensWithTrial(),
            FAQ.connectWithSeats.alreadyHaveConnect(),
            FAQ.connectWithSeats.invitedToConnectWithSeatsWillPriceChange(),
          ]}
        />
      </AppPage.Content>
    </AppPage.Container>
  );
};
