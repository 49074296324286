import { Typography } from '@remarkable/ark-web';
import { Check } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  CheckoutsVerifyIntentResponse,
  CheckoutType,
  StripeSubscription,
} from 'src/api/endpoints/storeApi.types';
import { Button, CardContent, Link, Sheet } from 'src/components';
import { StatusTag } from 'src/components/StatusTag';
import { formatDate } from 'src/utils/formatDate';
import { formatAmount } from 'src/utils/productUtils';
import { URLS } from 'src/utils/urls/urls';

import { PaymentInformation } from '../components/PaymentInformation';

export const SubscriptionSummaryCard = ({
  checkoutType,
  paymentConfirm,
  subscription,
  to,
}: {
  checkoutType: CheckoutType;
  paymentConfirm: CheckoutsVerifyIntentResponse;
  subscription: StripeSubscription;
  to: string;
}) => {
  const trialLength = () => {
    switch (checkoutType) {
      case 'retailOffer':
      case 'subscriptionOffer':
        return '1-year free Connect trial';
      case 'connectOffer':
        return '100-day free trial';
    }
  };
  const price = formatAmount(
    paymentConfirm.data.cartPrice.total,
    paymentConfirm.data.currency
  );

  return (
    <Sheet data-cy="confirmation-card" className="w-full">
      <div>
        <CardContent>
          <div className="flex flex-col gap-12 pb-32">
            <Typography variant="heading-lg">Welcome to Connect</Typography>
            <Typography variant="body-md-regular">
              You&apos;ve successfully activated your Connect subscription. Now
              you can take your notes even further.{' '}
            </Typography>
          </div>
        </CardContent>
        <CardContent borderTop>
          <div className="flex flex-row flex-wrap items-center">
            <Typography
              className="w-full lm:w-fit"
              variant="heading-subtle-2xl"
              data-cy="confirmation-card-heading-title"
            >
              {trialLength()}
            </Typography>
            <StatusTag className="mr-auto lm:ml-12" status="Active" />
            <Typography
              variant="interface-xl-semibold"
              data-cy="confirmation-card-heading-amount"
            >
              {price}
            </Typography>
          </div>
        </CardContent>

        <CardContent borderTop>
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-16">Billing starts:</p>
            <p
              data-cy="checkout-summary-billing-start-date"
              className="m-0 text-16"
            >
              {formatDate(
                subscription.paymentInformation?.nextInvoice?.dueDate
              )}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-16">Amount:</p>
            <p className="m-0 text-16" data-cy="checkout-summary-amount">
              {price}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="m-0 whitespace-nowrap text-16">Order id:</p>
            <p className="m-0 text-right text-16" data-cy="checkout-order-id">
              {paymentConfirm.data.orderId}
            </p>
          </div>

          <PaymentInformation subscription={subscription} />
        </CardContent>
      </div>
      <CardContent borderTop>
        <div className="flex flex-col items-center justify-between gap-32">
          <p className="m-0 text-16">
            We&apos;ll send you a reminder email before your free subscription
            period ends. View your current subscription or update your payment
            card details at the{' '}
            <Link to={URLS.SUBSCRIPTION} inline>
              subscription page
            </Link>
            .
          </p>
          <Button
            type="button"
            as="a"
            className="ml-auto w-full lm:w-fit"
            variant="primary"
            to={to}
            analytics={{
              location: ComponentLocations.CHECKOUT.CONFIRMATION,
              text: 'Next',
              action: 'go to next page',
            }}
          >
            <span>Next</span>
            <Check />
          </Button>
        </div>
      </CardContent>
    </Sheet>
  );
};
