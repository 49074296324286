import { createFileRoute } from '@tanstack/react-router';

import { Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { EnterpriseSubscriptionCard } from 'src/components/EnterpriseSubscriptionCard';

export const Route = createFileRoute(
  '/_auth/_layout/enterprise/_with-org/_layout/plan'
)({
  component: () => (
    <AppPage.Content className="max-w-l2xl">
      <Sheet>
        <EnterpriseSubscriptionCard />
      </Sheet>
    </AppPage.Content>
  ),
});
