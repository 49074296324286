import {
  FreeConnectSubscription,
  StripeSubscription,
  StripeSubscriptionCancelled,
  StripeSubscriptionDeactivated,
  Subscription,
  SubscriptionStatus,
} from 'src/api/endpoints/storeApi.types';

const isStripe = (sub?: Subscription): sub is StripeSubscription =>
  sub?.type === 'stripe';

const isFreeConnect = (sub?: Subscription): sub is FreeConnectSubscription =>
  sub?.type === 'free-connect';

const isCancelled = (
  sub?: Subscription
): sub is StripeSubscriptionCancelled => {
  if (!isStripe(sub)) return false;

  if (!sub.paymentInformation) {
    return sub.status === 'canceled';
  }

  return sub.paymentInformation.isCancelled;
};

const isDeactivated = (
  sub?: Subscription
): sub is StripeSubscriptionDeactivated => {
  if (!isStripe(sub)) return false;

  if (!sub.paymentInformation) {
    return sub.status === 'unpaid';
  }

  return sub.paymentInformation.isDeactivated;
};

const getStatus = (sub?: Subscription): SubscriptionStatus => {
  if (!sub) return SubscriptionStatus.NoSubscription;

  if (isFreeConnect(sub)) return SubscriptionStatus.Active;

  if (isCancelled(sub)) return SubscriptionStatus.Cancelled;

  if (isDeactivated(sub)) return SubscriptionStatus.Deactivated;

  return SubscriptionStatus.Active;
};

const isActive = (sub?: Subscription): boolean =>
  getStatus(sub) === SubscriptionStatus.Active;

const isPaymentFailing = (sub?: Subscription): sub is Subscription =>
  sub?.status === 'incomplete' || sub?.status === 'unpaid';

const isManagementTypeSeats = (sub?: Subscription): sub is StripeSubscription =>
  isStripe(sub) && sub.managementType === 'seats';

const isManagementTypeSimple = (
  sub?: Subscription
): sub is StripeSubscription =>
  isStripe(sub) && sub.managementType === 'simple';

export const subscriptionHelpers = {
  getStatus,
  isActive,
  isCancelled,
  isDeactivated,
  isFreeConnect,
  isManagementTypeSeats,
  isManagementTypeSimple,
  isPaymentFailing,
  isStripe,
};
