import { HTMLAttributes } from 'react';

import { ArrowRight } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { useStripeSubscriptionSuspense } from 'src/api/queries';
import connectMultipleSeatsPreviewWebp from 'src/assets/img/connect-multiple-seats-preview.webp';
import { cn } from 'src/utils/classNamesHelper';
import { SKU } from 'src/utils/skus';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

import { Button, Modal } from '.';
import { ConnectMultipleSeatsModal } from './ConnectMultipleSeatsModal';
import { ImageWithFallback } from './ImageWithFallback';
import { Price } from './Price';

export function ConnectMultipleSeatsCard({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const subscription = useStripeSubscriptionSuspense();

  const showEnableSeatsButton =
    subscriptionHelpers.isActive(subscription.data) &&
    !subscriptionHelpers.isManagementTypeSeats(subscription.data);

  return (
    <div
      data-cy="connect-multiple-seats-card"
      {...props}
      className={cn('flex flex-col gap-24 lg:flex-row', className)}
    >
      <div className="flex flex-1 flex-col justify-center gap-16">
        <div>
          <Typography
            variant="interface-sm-caps-medium"
            className="text-pen-blue"
          >
            New
          </Typography>
          <Typography variant="heading-subtle-2xl">
            Invite users to your Connect subscription
          </Typography>
        </div>

        <div className="flex flex-col gap-8">
          <Typography variant="body-md-regular">
            Add multiple users to your subscription — perfect for small
            businesses and teams.
          </Typography>

          <Typography variant="body-md-regular">
            The users you add get access to all features and benefits included
            with Connect.
          </Typography>
        </div>

        {showEnableSeatsButton && (
          <Modal.Root>
            <Modal.Trigger asChild>
              <Button
                data-cy="upgrade-to-multiple-seats-button"
                variant="primary"
                className="mt-8"
              >
                <span>Enable this feature</span> <ArrowRight />
              </Button>
            </Modal.Trigger>

            <ConnectMultipleSeatsModal />
          </Modal.Root>
        )}

        <Typography variant="interface-sm" className="leading-6 text-muted">
          <Price sku={SKU.connectForBusinessMonthly} />
          /mo. per user. Cancel anytime. Annual subscribers switch to monthly
          payment.
        </Typography>
      </div>

      <ImageWithFallback
        className="flex-1"
        // imgClassName="h-full"
        sources={[{ url: connectMultipleSeatsPreviewWebp, type: 'image/webp' }]}
        alt="Invite modal preview"
      />
    </div>
  );
}
