import { Spinner as ArkSpinner } from '@remarkable/ark-web';

export const Spinner = ({ inline }: { inline?: boolean }) => {
  if (inline) {
    return (
      <ArkSpinner
        data-cy="spinner"
        size="small"
        className="mx-4 inline-block w-fit"
      />
    );
  }

  return <ArkSpinner data-cy="spinner" size="large" className="m-auto w-fit" />;
};
