import { Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';

import landingImg from 'src/assets/img/landing-remarkable.jpg';
import { Button } from 'src/components';

export const DeviceSetupLandingPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full  items-center justify-center bg-neutral-light-1 p-16 lm:p-64 ll:h-screen lxl:p-128">
      <div className="grid w-full max-w-7xl bg-white shadow-2xl ll:grid-cols-[3fr,2fr]">
        {/* Content with Buttons */}
        <div className="flex h-fit flex-col items-center justify-between gap-16 p-40 text-center ll:order-2 ll:pt-160">
          <Typography variant="heading-lg" className="font-bold" as="h1">
            reMarkable
          </Typography>
          <Typography variant="heading-subtle-2xl" as="h2">
            Pair device
          </Typography>
          <Typography variant="body-sm-regular">
            You need to log in to verify your pairing code.
          </Typography>
          <div className="flex max-w-screen-xs flex-col items-center justify-center gap-16">
            <Button
              className="w-full"
              onClick={async () =>
                navigate({
                  to: '/login',
                  search: { redirect: '/device/browser/connect' },
                })
              }
            >
              Log in
            </Button>
            <Button
              variant="secondary"
              className="w-full"
              onClick={async () =>
                navigate({
                  to: '/login',
                  search: {
                    redirect: '/device/browser/connect',
                    screen_hint: 'signup',
                  },
                })
              }
            >
              Create account
            </Button>
          </div>
        </div>

        {/* Content with Image */}
        <div className="flex flex-col justify-center bg-neutral-dark-7 ll:order-1">
          <div className="relative w-full overflow-hidden">
            <img
              src={landingImg} // Replace with your image path
              alt=""
              className="h-full max-h-[600px] object-contain object-bottom"
              style={{ objectPosition: 'center 75%' }}
            />
            <div className="absolute inset-0 z-0 bg-gradient-to-t from-neutral-dark-7 via-transparent to-transparent"></div>
          </div>
          <div className="flex w-full justify-center">
            <div className="w-1/2 min-w-[250px]">
              <Typography className="text-white" variant="heading-md" as="h2">
                Improve your experience with myreMarkable
              </Typography>
              <div className="flex h-160 justify-center bg-neutral-dark-7">
                <Typography
                  className="mt-16 text-white"
                  variant="body-md-regular"
                >
                  Set up and manage your reMarkable universe. Sync content with
                  our apps, integrate with cloud storage services, and try using
                  Read on reMarkable.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
