import { queryOptions, useQuery } from '@tanstack/react-query';

import { SKU } from 'src/utils/skus';

import { storeApi } from '../endpoints';
import { useCountry } from './country';
import { queryKeys } from './queryKeys';

const queryProductPricesPublic = (sku: SKU | null) =>
  queryOptions({
    queryKey: queryKeys.product(sku),
    queryFn: () => {
      if (!sku) {
        throw new Error('No SKU provided');
      }

      return storeApi.getProductPricesPublic(sku);
    },
    staleTime: Infinity,
  });

export const useProductPrice = (sku?: SKU, currency?: string) => {
  const country = useCountry();

  const targetCurrency = currency ?? country.data?.currency;

  return useQuery({
    ...queryProductPricesPublic(sku ?? null),
    select: (data) => data.find((price) => price.currency === targetCurrency),
    enabled: !!country.isSuccess && !!sku,
  });
};
