import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useRemoveUserFromSubscription } from 'src/api/queries';
import { Button, Modal2, NotificationBox } from 'src/components';

import { MyRmSubscriptionMembersHandlersTypes } from '../../../../api/endpoints/storeApi.types';
import { createModalHook } from '../../../../utils/useModal';

export const useRemoveUserFromSubscriptionModal =
  createModalHook<MyRmSubscriptionMembersHandlersTypes.ListMembers.Member>(
    'RemoveUserFromSubscription'
  );

export const RemoveUserFromSubscriptionModal = ({ id }: { id: string }) => {
  const modal = useRemoveUserFromSubscriptionModal();
  const removeUserFromSubscription = useRemoveUserFromSubscription();

  if (modal.isOpen === false || modal.data.id !== id) return null;

  return (
    <Modal2
      onClose={() => {
        removeUserFromSubscription.reset();
        modal.close();
      }}
    >
      <Typography variant="heading-md" className="mb-24">
        Remove member
      </Typography>

      <Typography variant="body-md-regular" className="mb-16 whitespace-normal">
        Are you sure you want to remove{' '}
        <span data-cy="member-email">{modal.data.email}</span>?
      </Typography>

      <Typography variant="body-md-regular" className="my-16">
        This member will keep access to their files, but lose access to all
        subscription features and benefits. If they had a free trial before
        joining this subscription, they won&apos;t be able to resume their trial
        if you remove them.
      </Typography>

      {removeUserFromSubscription.isError && (
        <NotificationBox
          className="my-16"
          variant="error"
          title="Something went wrong"
        >
          Something went wrong while removing the member from the seat. Please
          try again or contact support if the problem persists.
        </NotificationBox>
      )}

      <div className="mt-24 flex flex-col flex-wrap justify-center gap-16 ls:flex-row">
        <Button
          className="w-full ls:w-fit"
          variant="secondary"
          onClick={() => {
            removeUserFromSubscription.reset();
            modal.close();
          }}
          analytics={{
            text: 'Keep member',
            location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
            action: 'keep member (close modal)',
          }}
        >
          Keep member
        </Button>

        <Button
          className="w-full ls:w-fit"
          loading={removeUserFromSubscription.isPending}
          data-cy="confirm-remove-user"
          onClick={() => {
            if (!modal.data?.id) return;

            removeUserFromSubscription.mutate(
              { subscriptionRoleId: modal.data.id },
              { onSuccess: modal.close }
            );
          }}
          analytics={{
            text: 'Remove member',
            location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
            action: 'remove member',
          }}
        >
          Remove member
        </Button>
      </div>
    </Modal2>
  );
};
