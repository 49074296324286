// Members ---------------------------------------------------------------------
import { cloudEnterpriseFleetApiClient as fleetApiClient } from '../clients/cloudEnterpriseFleetApiClient';

export const getMembers = async () =>
  fleetApiClient.GET('/fleet/v1/users').then((res) => res?.data ?? null);

export const getOrgInfo = async () =>
  fleetApiClient.GET('/fleet/v1/organization').then((res) => res?.data ?? null);

export const getInvitations = async () =>
  fleetApiClient.GET('/fleet/v1/invitations').then((res) => res.data ?? null);

export const createInvitation = async (vars: { email: string }) =>
  fleetApiClient.POST('/fleet/v1/invitations', {
    body: {
      email: vars.email,
    },
  });
