import { HTMLAttributes, useRef } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-router';
import clsx from 'clsx';
import { Plus } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useAccessTokenClaims } from 'src/api/queries/user';
import { Button, Divider, Modal, Tooltip } from 'src/components';
import { InviteToEnterpriseByEmail } from 'src/components/InviteToEnterpriseByEmail';
import { exitAnimationDuration } from 'src/components/Modal';
import { userHelpers } from 'src/utils/userHelpers';

export const InviteToEnterpriseButton = ({
  className,
  ...rest
}: HTMLAttributes<HTMLButtonElement>) => {
  const accessToken = useAccessTokenClaims();
  const navigate = useNavigate({ from: '/enterprise/members' });
  const search = useSearch({ strict: false });
  const exitAnimationTimeout = useRef<NodeJS.Timeout | null>(null);

  return (
    <Modal.Root
      onOpenChange={(isOpen) => {
        if (exitAnimationTimeout.current) {
          clearTimeout(exitAnimationTimeout.current);
        }

        if (!isOpen) {
          // Wait for the animation to finish before resetting the page url
          // This is to avoid the modal from collapsing mid-animation
          exitAnimationTimeout.current = setTimeout(() => {
            void navigate({
              search: (prev) => ({ ...prev, invite_by: undefined }),
            });
          }, exitAnimationDuration);

          return;
        }

        if (!search.invite_by) {
          void navigate({ search: { invite_by: 'email' } });
        }
      }}
    >
      <Tooltip
        enabled={!userHelpers.hasPermissionCreateInvitations(accessToken.data)}
        title="You don't have permission to create invitations"
      >
        <Modal.Trigger asChild>
          <Button
            variant="tertiary"
            className={clsx('w-full ls:w-auto', className)}
            data-cy="open-invite-modal-button"
            disabled={
              !userHelpers.hasPermissionCreateInvitations(accessToken.data)
            }
            analytics={{
              text: 'Invite',
              location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
              action: 'open invite modal',
            }}
            {...rest}
          >
            <Plus />
            <span>Invite</span>
          </Button>
        </Modal.Trigger>
      </Tooltip>

      <Modal.Content title="Invite">
        <Divider className="my-16" />

        <InviteToEnterpriseByEmail />
      </Modal.Content>
    </Modal.Root>
  );
};
