import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { FaqSection, Feedback, Spinner } from 'src/components';
import { FAQ } from 'src/constants/FAQ';
import { SKU } from 'src/utils/skus';

import { MembersTable } from '../components/MembersTable';
import { SubscriptionSeatsInfo } from '../components/SubscriptionSeatsInfo';
import { useMembersList } from '../utils/useMembersList';

export const MembersPage = () => {
  const members = useMembersList();

  if (members.isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-24 lm:gap-32">
      <SubscriptionSeatsInfo />

      <MembersTable />

      {members.data.length <= 1 && (
        <div className="flex flex-col items-center justify-center bg-neutral-light-3 p-24">
          <Typography variant="body-md-bold" className="font-medium">
            Invite users to get started
          </Typography>

          <Typography variant="body-sm-regular">
            Need help? Find answers to common questions below.
          </Typography>
        </div>
      )}

      <FaqSection
        className="my-64"
        componentLocation={ComponentLocations.SUBSCRIPTION.MEMBERS_PAGE}
        sections={[
          FAQ.connectWithSeats.whatHappensToTeamMembersFiles(),
          FAQ.connectWithSeats.canIAccessTeamMembersFiles(),
          FAQ.connectWithSeats.whatHappensIfIRemoveAUser(),
          FAQ.connectWithSeats.whatDoesActiveSeatMean(),
          FAQ.connectWithSeats.howDoesBillingWork(
            SKU.connectForBusinessMonthly
          ),
          FAQ.connectWithSeats.asAnOwnerIDontNeedAccessToSubscriptionFeatures(),
          FAQ.connectWithSeats.whatIsTheDifferenceBetweenTheUserManagerAndOwnerRoles(),
        ]}
      />

      <Feedback
        feedbackTargetId="c4b-members-page-for-owner-v1"
        title="Did you find what you were looking for?"
      />
    </div>
  );
};
