import { Typography } from '@remarkable/ark-web';
import { ArrowCounterClockwise } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  useAbortCancelSubscription,
  useStripeSubscription,
} from 'src/api/queries';
import { Button, NotificationBox } from 'src/components';
import { NotificationBanner } from 'src/components/NotificationBanner';
import { formatDate } from 'src/utils/formatDate';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';

export const SubscriptionCanceledNotification = () => {
  const subscription = useStripeSubscription();
  const abortCancelSubscription = useAbortCancelSubscription();

  if (subscription.isLoading || subscription.isError) return null;

  if (!subscriptionHelpers.isCancelled(subscription.data)) return null;

  const info = subscription.data.paymentInformation;

  return (
    <NotificationBanner>
      <div className="flex flex-col gap-16">
        <Typography variant="heading-subtle-lg" className="my-0">
          Your subscription ends {formatDate(info?.cancellationAt)}
        </Typography>

        <Typography variant="interface-md-regular" className="my-0 leading-6">
          {subscription.data.managementType == 'seats'
            ? `All users will lose access to the included features, and
          you'll no longer be charged. Undo your cancelation to keep
          Connect.`
            : `You will lose access to the included features, and
          you'll no longer be charged. Undo your cancelation to keep
          Connect.`}
        </Typography>

        {abortCancelSubscription.isError && (
          <NotificationBox variant="error" title="Something went wrong">
            We couldn&apos;t undo your cancelation. Please try again later or
            contact support if the problem persists.
          </NotificationBox>
        )}

        <Button
          variant="tertiary"
          size="medium"
          className="mt-8 w-full xs:w-fit"
          loading={abortCancelSubscription.isPending}
          onClick={() => {
            abortCancelSubscription.mutate();
          }}
          analytics={{
            text: 'Undo cancelation',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'undo cancelation',
          }}
        >
          <ArrowCounterClockwise /> <span>Undo cancelation</span>
        </Button>
      </div>
    </NotificationBanner>
  );
};
