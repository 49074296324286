import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { SubscriptionUpdateFailed, SubscriptionUpdated } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { storeApi } from 'src/api/endpoints';
import {
  queryKeys,
  useCurrentMember,
  useStripeSubscription,
} from 'src/api/queries';
import { deriveSubscriptionName } from 'src/utils/deriveSubscriptionName';
import { formatShortDate } from 'src/utils/formatDate';
import { formatAmount } from 'src/utils/productUtils';
import { SKU } from 'src/utils/skus';
import { uuidRegex } from 'src/utils/uuidRegex';

import { HTTPError } from '../createApiClient.types';
import {
  Payment,
  PaymentInterval,
  StripeSubscription,
} from '../endpoints/storeApi.types';

const chargeTotal = (charge: Payment): string => {
  const total = `${formatAmount(charge.amount.total, charge.amount.currency)}${
    charge.paid ? '' : ' (failed)'
  }`;

  if (!charge.refund) {
    return total;
  }

  return `+ ${total}`;
};

const chargeDescription = (sku: typeof SKU, refund: boolean): string => {
  const name = nameFromSku(sku);
  if (!refund) {
    return name;
  }

  return `Refund - ${name}`;
};

const nameFromSku = (sku: typeof SKU): string => {
  if (!sku) return ''; // TODO: Figure out why this is happens

  switch (sku.toString()) {
    case SKU.connectMonthly:
      return 'Connect monthly';
    case SKU.connectAnnually:
      return 'Connect annually';
    case SKU.connectForBusinessMonthly:
      return 'Connect for business monthly';
    case SKU.connectForBusinessAnnually:
      return 'Connect for business annually';
    default:
      return 'Connect';
  }
};

export interface PaymentWithDisplay extends Payment {
  display: {
    date: string;
    description: string;
    total: string;
  };
}

export const createQueryPaymentHistory = () =>
  queryOptions({
    queryKey: queryKeys.settings.paymentHistory,
    queryFn: storeApi.getPaymentHistory,
    staleTime: Infinity,
    select(data) {
      return data.map<PaymentWithDisplay>((charge) => ({
        ...charge,
        display: {
          date: formatShortDate(charge.createdAt, { showYear: true }),
          description: chargeDescription(charge.sku, charge.refund),
          total: chargeTotal(charge),
        },
      }));
    },
  });

export const usePaymentHistory = () => {
  return useQuery(createQueryPaymentHistory());
};

export const useUpdatePaymentInterval = () => {
  const queryClient = useQueryClient();
  const subscription = useStripeSubscription();
  const currentMember = useCurrentMember();
  const action = 'change payment interval';

  return useMutation({
    mutationFn: ({
      newInterval,
    }: {
      sub: StripeSubscription;
      newInterval: PaymentInterval;
    }) => {
      if (!subscription.data) {
        throw new Error('No subscription found or is still loading');
      }

      if (!currentMember.data) {
        throw new Error('No member found');
      }

      return storeApi.updatePaymentInterval({
        interval: newInterval,
        subscriptionId: subscription.data.id,
      });
    },
    onSuccess: (_, { newInterval, sub }) => {
      tracker.trackEvent(
        new SubscriptionUpdated({
          action,
          subscription_name: deriveSubscriptionName(sub)?.name ?? 'unknown',
          payment_interval: newInterval,
          subscription_status: sub.status,
          subscription_created_at: sub.createdAt,
          created_at: sub.createdAt,
          expires_at: sub.paymentInformation?.nextInvoice?.dueDate ?? undefined,
          updated_at: new Date().toISOString(),
        })
      );

      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
    async onError(error, { newInterval, sub }) {
      const error_name = 'api error';
      let error_code = 'not available';
      let error_message = 'not available';
      let error_api_url = 'not available';

      if (error instanceof HTTPError) {
        error_code = error.response.status.toString();
        error_message = (await error.response.text()) ?? 'not available';
        error_api_url = error.response.url.replace(uuidRegex, ':uuid');
      } else if (error instanceof Error) {
        error_message = error.message;
      }

      tracker.trackEvent(
        new SubscriptionUpdateFailed({
          action,
          subscription_name: deriveSubscriptionName(sub)?.name ?? 'unknown',
          payment_interval: newInterval,
          subscription_status: sub.status,
          subscription_created_at: sub.createdAt,
          expires_at: sub.paymentInformation?.nextInvoice?.dueDate ?? '',
          error_code,
          error_message,
          error_name,
          error_api_url,
        })
      );
    },
  });
};
