import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { Button, NotificationBox } from 'src/components';
import { IS_DEVELOPMENT } from 'src/config';
import { sanitizeRedirectUrl } from 'src/utils/sanitizeRedirectUrl';

const searchSchema = z.object({
  error: z.string().optional(),
  error_description: z.string().optional(),
  redirect_path: z.string().optional(),
});

export const Route = createFileRoute('/(public)/authentication-error')({
  validateSearch: zodSearchValidator(searchSchema),
  component: () => {
    const auth = useAuth0();
    const search = Route.useSearch();

    const redirectPath = sanitizeRedirectUrl(search.redirect_path);

    return (
      <div data-cy="error-page" className="w-full bg-neutral-light-2">
        <div className="m-auto flex max-w-screen-md flex-col items-center gap-24 px-24 py-64 md:px-64 lg:py-128">
          <Typography variant="heading-lg" className="text-center">
            Something went wrong during authentication
          </Typography>

          {search.error_description && IS_DEVELOPMENT && (
            <NotificationBox title={search.error ?? 'Error'} variant="error">
              {search.error_description}
            </NotificationBox>
          )}

          <Typography variant="body-md-regular" className="text-center">
            Please try again later or login with another account.
          </Typography>

          <div className="mt-32 flex gap-24">
            {redirectPath ? (
              <Button as="a" to={redirectPath}>
                Go back
              </Button>
            ) : (
              <Button as="a" to="/">
                Go home
              </Button>
            )}

            <Button variant="secondary" onClick={() => auth.logout()}>
              Login with another account
            </Button>
          </div>
        </div>
      </div>
    );
  },
});
