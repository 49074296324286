import { useState } from 'react';

import { Check, Warning, X } from '@phosphor-icons/react';
import { Divider, Typography } from '@remarkable/ark-web';
import { getRouteApi, Navigate } from '@tanstack/react-router';

import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseEnrollmentVerify as useEnterpriseVerifyDomain,
} from 'src/api/queries/enterpriseEnrollment';
import {
  Button,
  Input,
  NotificationBox,
  Sheet,
  Spinner,
  Tooltip,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { CopyButton } from 'src/components/CopyButton';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

const routeApi = getRouteApi('/_auth/_layout/enterprise/enroll/verify-domain');

export function VerifyDomainPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const verifyDomain = useEnterpriseVerifyDomain();
  const domain = enrollmentStatus.data?.primaryDomain ?? 'Not available';
  const navigate = routeApi.useNavigate();
  const [error, setError] = useState<string | null>(null);

  if (!enrollmentStatus.data?.primaryDomain) {
    // User has not initiated the enrollment yet
    return <Navigate to=".." />;
  }

  const DomainStatus = (() => {
    switch (enrollmentStatus.data?.domainVerification) {
      case 'OK':
        return (
          <div className="flex items-center gap-4 text-pen-blue">
            <Check className="" size={24} />
            <Typography variant="body-md-regular">Verified</Typography>
          </div>
        );
      case 'IN_PROGRESS':
        return (
          <div className="flex items-center gap-4">
            <Spinner inline />
            <Typography variant="body-md-regular">In progress</Typography>
          </div>
        );
      case 'INVALID':
        return (
          <div className="flex items-center gap-4">
            <Warning className="text-feedback-orange-500" size={24} />
            <Typography variant="body-md-regular">Invalid</Typography>
          </div>
        );
      case 'HOST_UNREACHABLE':
        return (
          <div className="flex items-center gap-4">
            <Warning className="text-feedback-orange-500" size={24} />
            <Typography variant="body-md-regular">Host unreachable</Typography>
          </div>
        );
      case 'IDLE':
      default:
        return (
          <div className="flex items-center gap-4">
            <X className="text-neutral-dark-1" size={24} />
            <Typography variant="body-md-regular">Not verified</Typography>
          </div>
        );
    }
  })();

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet>
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Verify domain
            </Typography>

            <CancelSetupModal />
          </div>

          <Divider />

          <div className="flex justify-between gap-16 px-16">
            <Typography variant="body-md-regular">{domain}</Typography>

            {DomainStatus}
          </div>

          <Divider />

          <Typography variant="body-md-regular">
            Paste this code in to the TXT record in your{' '}
            <Tooltip title="Domain Name Server">
              <span className="cursor-help">DNS</span>
            </Tooltip>{' '}
            to verify your domain{' '}
          </Typography>

          <div className="flex gap-24">
            <Input
              name="code"
              value={enrollmentStatus.data?.dnsTextRecord}
              placeholder="Loading..."
              disabled
            />
            <CopyButton
              value={enrollmentStatus.data?.dnsTextRecord}
              disabled={!enrollmentStatus.data?.dnsTextRecord}
            />
          </div>

          <Typography
            variant="interface-sm-regular"
            className="text-neutral-dark-1"
          >
            Verification can take some time. You can safely leave this page and
            continue later.
          </Typography>

          <div className="flex justify-center gap-24">
            <Button
              as="a"
              variant="secondary"
              to={URLS.ENTERPRISE_CREATE_ORGANIZATION}
            >
              Previous
            </Button>
            <Button
              variant="primary"
              disabled={verifyDomain.isPending}
              loading={verifyDomain.isPending}
              onClick={() => {
                if (enrollmentStatus.data?.domainVerification === 'OK') {
                  void navigate({ to: '../setup-saml' });
                } else if (
                  enrollmentStatus.data?.domainVerification !== 'IN_PROGRESS'
                ) {
                  verifyDomain.mutate(undefined, {
                    async onSuccess() {
                      const newStatus = await enrollmentStatus.refetch();

                      if (newStatus.data?.domainVerification === 'OK') {
                        void navigate({ to: '../setup-saml' });
                      }
                    },
                  });
                } else {
                  if (
                    enrollmentStatus.data.domainVerification === 'IN_PROGRESS'
                  ) {
                    setError(
                      'Please wait for the domain verification to complete.'
                    );
                  } else {
                    setError(
                      'The domain has to be verified to continue the setup process.'
                    );
                  }
                }
              }}
            >
              {enrollmentStatus.data.domainVerification === 'OK' ||
              enrollmentStatus.data.domainVerification === 'IN_PROGRESS'
                ? 'Continue'
                : 'Check domain'}
            </Button>
          </div>

          {error && (
            <Typography
              variant="interface-sm-regular"
              className="text-feedback-red-500"
            >
              {error}
            </Typography>
          )}

          {verifyDomain.isError && (
            <NotificationBox variant="error" title={'Something went wrong'}>
              {verifyDomain.error.message ||
                'Please try again later or contact support if the problem persists.'}
            </NotificationBox>
          )}
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
