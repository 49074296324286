import { Info } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import {
  useProductPrice,
  useStripeSubscription,
  useUpdateSubscriptionToSeats,
} from 'src/api/queries';
import { useNotifications } from 'src/hooks/useNotifications';
import { formatAmount } from 'src/utils/productUtils';
import { SKU } from 'src/utils/skus';

import { Button, Modal, NotificationBox } from '.';

export function ConnectMultipleSeatsModal() {
  const subscription = useStripeSubscription();
  const updateToSeats = useUpdateSubscriptionToSeats();
  const notifications = useNotifications();

  const connectForBusinessMonthlyPrice = useProductPrice(
    SKU.connectForBusinessMonthly
  );
  const price = formatAmount(
    connectForBusinessMonthlyPrice.data?.amount ?? 0,
    connectForBusinessMonthlyPrice.data?.currency
  );
  return (
    <Modal.Content
      className="flex max-w-4xl flex-col items-center justify-center gap-24"
      data-cy="enable-connect-multiple-seats-modal"
    >
      <div className="rounded-full bg-neutral-light-4 p-8">
        <Info size={32} />
      </div>

      <Typography variant="heading-lg">Enable user management?</Typography>

      <Typography variant="body-md-regular">
        Your subscription will be switched from yearly to monthly billing if you
        turn on this feature.
      </Typography>

      <Typography variant="body-md-regular">
        Connect is {price}/mo. Cancel anytime.
      </Typography>

      <div className="flex gap-24">
        <Button
          variant="primary"
          loading={updateToSeats.isPending}
          disabled={updateToSeats.isPending || !subscription.data}
          onClick={() => {
            updateToSeats.mutate(
              { subscriptionId: subscription.data?.id },
              {
                onSuccess() {
                  notifications.set({
                    id: 'JOINED_CONNECT_SEATS_AS_OWNER',
                  });
                },
              }
            );
          }}
        >
          Continue
        </Button>

        <Modal.Close asChild>
          <Button variant="secondary">Cancel</Button>
        </Modal.Close>
      </div>

      {updateToSeats.isError && (
        <NotificationBox variant="error" title="Something went wrong">
          Please try again later or contact support if the problem persists.
        </NotificationBox>
      )}
    </Modal.Content>
  );
}
