import { useMemo } from 'react';

import { Check, DotsThreeVertical } from '@phosphor-icons/react';
import {
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  ItemIndicator,
} from '@radix-ui/react-dropdown-menu';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { FleetUser } from 'src/api/endpoints/cloudEnterprise.types';
import {
  queryEnterpriseRoles,
  useAddRole,
  useDeleteRole,
} from 'src/api/queries/enterpriseAdmin';
import { Button } from 'src/components';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLabel,
} from 'src/components/DropdownMenu';

import { mergeRoleLists } from '../utils/mergeRoleLists';

export function MemberActionMenu({ member }: { member: FleetUser }) {
  const addRole = useAddRole();
  const deleteRole = useDeleteRole();
  const roles = useQuery(queryEnterpriseRoles());

  const roleList = useMemo(() => {
    return mergeRoleLists(roles.data ?? [], member.roles ?? []);
  }, [roles.data, member.roles]);

  return (
    <DropdownMenu
      trigger={
        <Button
          variant="tertiary-neutral"
          className="!rounded data-[state=open]:bg-interaction-tertiary-active"
          size="small"
          data-cy={`actions-button-cy_${member.email ?? 'unknown'}`}
          analytics={{
            text: 'DotsThreeVertical icon',
            location: ComponentLocations.FLEET.FLEET_MEMBERS_TABLE,
            action: 'open member actions menu',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <DotsThreeVertical />
        </Button>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownMenuGroup>
        <DropdownMenuLabel>Roles</DropdownMenuLabel>
        {roleList.map((role) => (
          <DropdownMenuItem key={role.id}>
            <DropdownMenuCheckboxItem
              key={role.id}
              checked={role.hasRole}
              onCheckedChange={(newState) => {
                if (newState === true) {
                  addRole.mutate({
                    memberId: member.id,
                    roleId: role.id,
                  });
                } else {
                  deleteRole.mutate({
                    memberId: member.id,
                    roleId: role.id,
                  });
                }
              }}
              className={clsx(
                'flex items-center gap-2 pl-24',
                role.isNotGloballyAvailableRole && 'text-muted'
              )}
            >
              <ItemIndicator className="absolute left-20">
                <Check />
              </ItemIndicator>
              <span>{role.name}</span>
            </DropdownMenuCheckboxItem>
          </DropdownMenuItem>
        ))}
      </DropdownMenuGroup>
    </DropdownMenu>
  );
}
