import { ForwardedRef, forwardRef, Fragment } from 'react';

import { Planet, Warning, X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { formatDistanceToNow } from 'date-fns';

import { FleetUser } from 'src/api/endpoints/cloudEnterprise.types';
import { Button, Divider, Tooltip } from 'src/components';
import { CopyButton } from 'src/components/CopyButton';
import { DeviceIcon } from 'src/components/DeviceIcon';
import {
  DrawerClose,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from 'src/components/Drawer';
import { formatShortDate } from 'src/utils/formatDate';

import { getDeviceName } from '../utils/getDeviceName';
import { getFullName } from '../utils/getFullName';
import { getIntegrationName } from '../utils/getIntegrationName';
import { IntegrationIcon } from './IntegrationIcon';

export const MemberDrawer = forwardRef(function renderMemberDrawer(
  { data }: { data?: FleetUser | null },
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  if (!data) {
    return (
      <div
        className="flex h-full w-full min-w-0 flex-col justify-center gap-16 p-24"
        ref={forwardedRef}
      >
        <div className="flex w-full items-start justify-between gap-16">
          <DrawerHeader>
            <DrawerTitle>
              <Typography variant="heading-xs">No matching user</Typography>
            </DrawerTitle>
            <DrawerDescription>
              Please select a user in the table.
            </DrawerDescription>
          </DrawerHeader>
        </div>
      </div>
    );
  }

  const fullName = getFullName(data);

  return (
    <div
      className="max-w-screen flex h-full max-h-screen w-full min-w-0 items-center"
      ref={forwardedRef}
    >
      <div className="max-h-full w-full overflow-y-scroll">
        <div className="sticky top-0 flex w-full items-start justify-between gap-16 bg-neutral-light-1 p-24">
          <DrawerHeader>
            <DrawerTitle asChild>
              <Typography
                variant="heading-xs"
                as="h2"
                className="w-full truncate text-left"
              >
                {fullName || data.email}
              </Typography>
            </DrawerTitle>
            {!!fullName && (
              <DrawerDescription className="text-left">
                <span className="mr-8">{data.email}</span>
              </DrawerDescription>
            )}
          </DrawerHeader>
          <DrawerClose asChild>
            <Button
              variant="tertiary-neutral"
              size="medium"
              className="-mr-4 -mt-4"
            >
              <X />
            </Button>
          </DrawerClose>
        </div>

        <div className="flex flex-col gap-24 p-24">
          {data.issues?.length && (
            <ul className="flex flex-col gap-8">
              {data.issues.map((issue) =>
                issue.title ? (
                  <li
                    key={issue.title}
                    className="flex items-start gap-8 rounded bg-neutral-light-2 p-4"
                  >
                    <Warning
                      size={24}
                      className="shrink-0 text-feedback-orange-500"
                    />
                    <Typography
                      variant="interface-sm-regular"
                      className="mb-0 leading-5"
                    >
                      {issue.title}
                    </Typography>
                  </li>
                ) : null
              )}
            </ul>
          )}

          <div>
            <Typography variant="heading-xs" className="text-20">
              Account
            </Typography>

            <Divider />

            <div className="mt-16">
              <ul className="grid grid-cols-2">
                <li>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    Role
                  </Typography>
                  <Typography variant="body-sm-regular">
                    {data.roles?.map((r) => r.name).join(', ') || '-'}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    Status
                  </Typography>
                  <Typography variant="body-sm-regular">-</Typography>
                </li>
                {data.betaStatus?.enrolled && (
                  <li>
                    <Typography
                      variant="interface-sm-regular"
                      className="truncate text-neutral-dark-1"
                    >
                      Beta software updates
                    </Typography>
                    <Typography variant="body-sm-regular">
                      Enabled since{' '}
                      {formatShortDate(data.betaStatus.updatedAt, {
                        showYear: true,
                      })}{' '}
                      (version {data.betaStatus.version})
                    </Typography>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div>
            <Typography variant="heading-xs" className="text-20">
              Devices
            </Typography>

            <Divider />

            <ul className="mt-20 flex flex-col gap-16">
              {data.devices?.map((device, index) => (
                <Fragment key={device.serialNumber}>
                  {index > 0 && (
                    <li>
                      <Divider />
                    </li>
                  )}
                  <li
                    className="flex w-full flex-row gap-16"
                    key={device.serialNumber}
                  >
                    <div className="flex min-w-0 flex-1 items-center gap-8">
                      <DeviceIcon
                        device={device}
                        size={48}
                        weight="light"
                        className="shrink-0"
                      />
                      <div className="w-full min-w-0">
                        <Typography
                          variant="body-md-regular"
                          className="my-0 truncate font-medium"
                        >
                          {getDeviceName(device)}
                        </Typography>
                        <CopyButton
                          className="-ml-4 !min-h-0 max-w-full truncate !px-4 !py-2 text-14 !font-light"
                          value={device.serialNumber}
                          size="small"
                          variant="tertiary-neutral"
                        >
                          {device.serialNumber}
                        </CopyButton>
                      </div>
                    </div>
                    <ul className="flex min-w-0 flex-1 flex-col gap-2">
                      <li>
                        <Typography
                          variant="interface-sm-regular"
                          className="text-neutral-dark-1"
                        >
                          Last seen{' '}
                          <Tooltip
                            title={formatShortDate(device.lastSeen, {
                              showTime: true,
                              showYear: true,
                            })}
                          >
                            <span>
                              {formatDistanceToNow(
                                new Date(device.lastSeen ?? ''),
                                { addSuffix: true }
                              )}
                            </span>
                          </Tooltip>
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="interface-sm-regular"
                          className="text-neutral-dark-1"
                        >
                          <Tooltip title={device.osVersion}>
                            <span>
                              Version <span>{device.softwareVersion}</span>
                            </span>
                          </Tooltip>
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="interface-sm-regular"
                          className="text-neutral-dark-1"
                        >
                          Passcode digits:{' '}
                          <span>{device.passcodeDigits ?? 'N/A'}</span>
                        </Typography>
                      </li>
                    </ul>
                  </li>
                </Fragment>
              ))}
              {(data.devices?.length ?? 0) < 1 && (
                <li>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    No devices paired
                  </Typography>
                </li>
              )}
            </ul>
          </div>

          <div>
            <Typography variant="heading-xs" className="text-20">
              Apps
            </Typography>

            <Divider />

            <ul className="mt-20 flex flex-col gap-16">
              {data.companions?.map((companion, index) => (
                <Fragment key={companion.serialNumber}>
                  {index > 0 && (
                    <li>
                      <Divider />
                    </li>
                  )}
                  <li
                    className="flex w-full gap-16"
                    key={companion.serialNumber}
                  >
                    <div className="flex flex-1 items-center gap-16">
                      <DeviceIcon
                        device={companion}
                        size={48}
                        weight="light"
                        className="shrink-0"
                      />
                      <div>
                        <Typography
                          variant="body-md-regular"
                          className="font-medium"
                        >
                          {getDeviceName(companion)}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col">
                      <Typography
                        variant="interface-sm-regular"
                        className="text-neutral-dark-1"
                      >
                        Last seen
                      </Typography>
                      <Typography variant="body-sm-regular">
                        <Tooltip
                          title={formatShortDate(companion.lastSeen, {
                            showTime: true,
                            showYear: true,
                          })}
                        >
                          <span>
                            {formatDistanceToNow(
                              new Date(companion.lastSeen ?? ''),
                              { addSuffix: true }
                            )}
                          </span>
                        </Tooltip>
                      </Typography>
                    </div>
                  </li>
                </Fragment>
              ))}
              {(data.companions?.length ?? 0) < 1 && (
                <li>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    No apps paired
                  </Typography>
                </li>
              )}
            </ul>
          </div>

          <div>
            <Typography variant="heading-xs" className="text-20">
              Integrations
            </Typography>

            <Divider />

            <ul className="mt-20 flex flex-col gap-16">
              {data.integrations?.map((integration, index) => (
                <Fragment
                  key={
                    (integration.provider ?? '') +
                    (integration.createdAt ?? index.toString())
                  }
                >
                  {index > 0 && (
                    <li>
                      <Divider />
                    </li>
                  )}
                  <li className="flex w-full gap-16">
                    <div className="flex flex-1 items-center gap-16">
                      <IntegrationIcon
                        integration={integration}
                        fallback={<Planet size={40} weight="light" />}
                        className="w-40 shrink-0 p-4"
                      />
                      <div>
                        <Typography
                          variant="body-md-regular"
                          className="font-medium"
                        >
                          {getIntegrationName(integration)}
                          {integration.hasIssue && (
                            <Tooltip title="There is an issue with this integration. Please verify authentication on the integrations page.">
                              <Warning
                                size={24}
                                className="-mt-6 ml-6 inline-block text-feedback-orange-500"
                              />
                            </Tooltip>
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col">
                      <Typography
                        variant="interface-sm-regular"
                        className="text-neutral-dark-1"
                      >
                        Authenticated
                      </Typography>
                      <Typography variant="body-sm-regular">
                        <Tooltip
                          title={formatShortDate(integration.createdAt, {
                            showTime: true,
                            showYear: true,
                          })}
                        >
                          <span>
                            {formatDistanceToNow(
                              new Date(integration.createdAt ?? ''),
                              { addSuffix: true }
                            )}
                          </span>
                        </Tooltip>
                      </Typography>
                    </div>
                  </li>
                </Fragment>
              ))}
              {(data.integrations?.length ?? 0) < 1 && (
                <li>
                  <Typography
                    variant="interface-sm-regular"
                    className="text-neutral-dark-1"
                  >
                    No integrations added
                  </Typography>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
