import { Subscription } from 'src/api/endpoints/storeApi.types';

import { subscriptionHelpers } from './subscriptionHelpers';

export const subscriptionGetPaymentInterval = (
  sub?: Subscription
): 'monthly' | 'annually' | undefined => {
  if (!subscriptionHelpers.isStripe(sub) || !subscriptionHelpers.isActive) {
    return undefined;
  }

  return sub.paymentInformation?.paymentInterval || undefined;
};
