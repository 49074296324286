import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { cloudEnterpriseApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export function useFleetMembers() {
  return useQuery({
    queryKey: queryKeys.fleet.members,
    queryFn: () => cloudEnterpriseApi.getMembers(),
  });
}

export function useOrgInfo() {
  return useSuspenseQuery({
    queryKey: queryKeys.fleet.orgInfo,
    queryFn: () => cloudEnterpriseApi.getOrgInfo(),
  });
}
