import { AppPage } from 'src/components/AppPage';

import { FleetTable } from './components/FleetTable';
import { Overview } from './components/Overview';

export function EnterpriseMembersPage() {
  return (
    <AppPage.Content className="max-w-l2xl">
      <div className="flex flex-col gap-32">
        <Overview />
        <FleetTable />
      </div>
    </AppPage.Content>
  );
}
