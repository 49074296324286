import { Typography } from '@remarkable/ark-web';

import { Button, ErrorPage, Link } from 'src/components';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

export const AlreadyHaveFreeConnect = () => {
  return (
    <ErrorPage.Container data-cy="already-have-free-access-page">
      <ErrorPage.ErrorIcon />

      <ErrorPage.Heading>
        You already have free access to Connect
      </ErrorPage.Heading>

      <ErrorPage.Content>
        <div className="flex flex-col items-center justify-center gap-32 text-center">
          <Typography variant="body-md-regular">
            You can&apos;t join this subscription because you already have free
            access to Connect.
            <br />
            To join a subscription with this account, please{' '}
            <Link inline to={SUPPORT_URL.CONTACT_SUPPORT}>
              contact support
            </Link>
            .
          </Typography>

          <Button variant="secondary" as="a" to={URLS.HOME}>
            Back to home
          </Button>
        </div>
      </ErrorPage.Content>
    </ErrorPage.Container>
  );
};
