import { Typography } from '@remarkable/ark-web';
import { CheckCircle, X } from 'phosphor-react';
import toast, { Toast } from 'react-hot-toast';

export const PairingCompleteToast = (t: Toast) => (
  <div className="m-auto flex animate-fade-in-down flex-col rounded bg-neutral-light-3 p-16 shadow-md md:m-0">
    <div className="flex items-center justify-start gap-8">
      <CheckCircle size={16} weight="bold" />
      <Typography variant="body-md-bold" as="span">
        Pairing completed
      </Typography>
    </div>
    <Typography variant="body-md-regular" className="mr-8" as="p">
      You&apos;ve successfully paired your device.
    </Typography>

    <button
      className="absolute right-0 top-0 cursor-pointer p-16"
      onClick={() => toast.remove(t.id)}
      aria-label="Close"
    >
      <X />
    </button>
  </div>
);
