import { Typography } from '@remarkable/ark-web';
import { CreditCard } from 'phosphor-react';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { Button } from 'src/components';
import { formatDate } from 'src/utils/formatDate';
import { subscriptionHelpers } from 'src/utils/subscriptionHelpers';
import { URLS } from 'src/utils/urls/urls';

import { NotificationBanner } from '../../../components/NotificationBanner';

interface FreeConnectTermsAndConditionsProps {
  subscription?: Subscription;
}

export const PausedSubscriptionBanner = ({
  subscription,
}: FreeConnectTermsAndConditionsProps) => {
  if (
    subscriptionHelpers.isFreeConnect(subscription) ||
    !subscriptionHelpers.isDeactivated(subscription)
  ) {
    return null;
  }
  if (subscription.access.canChangePaymentInformation) {
    return (
      <NotificationBanner>
        <div>
          <Typography variant="body-md-bold" as="h3">
            Your subscription is on hold
          </Typography>
          <Typography variant="body-md-regular" className="mt-8" as="p">
            There was an error with your payment, and your subscription is on
            hold. Your team can still access their files, but subscription
            benefits are unavailable. To restore access, please change your card
            or update your payment details. Your subscription will be canceled{' '}
            <Typography as="span" variant="body-md-bold">
              {formatDate(subscription.paymentInformation.cancellationAt)}
            </Typography>
            , if you don&apos;t update your payment details.
          </Typography>
          <Button className="mt-24" as={'a'} to={URLS.SUBSCRIPTION_PAYMENT}>
            <CreditCard />
            <span>Update payment</span>
          </Button>
        </div>
      </NotificationBanner>
    );
  }
  return (
    <NotificationBanner>
      <div>
        <Typography variant="body-md-bold" as="h3">
          Your company&apos;s subscription is on hold
        </Typography>
        <Typography variant="body-md-regular" className="mt-8" as="p">
          Your subscription features are unavailable, but you can still access
          your files. Please ask your admin to update their payment details.
        </Typography>
      </div>
    </NotificationBanner>
  );
};
