import { Typography } from '@remarkable/ark-web';

import { Button, ErrorPage } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const AlreadyInSeatManagement = () => {
  return (
    <ErrorPage.Container data-cy="already-in-seat-management-page">
      <ErrorPage.ErrorIcon />

      <ErrorPage.Heading>You already subscribe to Connect</ErrorPage.Heading>

      <ErrorPage.Content>
        <div className="flex flex-col items-center justify-center gap-32 text-center">
          <Typography variant="body-md-regular">
            To accept a new invitation, please cancel or leave your current
            subscription.
          </Typography>

          <Button variant="secondary" as="a" to={URLS.HOME}>
            Back to home
          </Button>
        </div>
      </ErrorPage.Content>
    </ErrorPage.Container>
  );
};
