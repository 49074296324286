import {
  FleetCompanion,
  FleetDevice,
  FleetIntegration,
  FleetMemberRow,
} from 'src/api/endpoints/cloudEnterprise.types';
import { formatShortDate } from 'src/utils/formatDate';

import { getIntegrationName } from '../utils/getIntegrationName';

const isUser = (row: FleetMemberRow) => row.type === 'user';
const isInvitation = (row: FleetMemberRow) => row.type === 'invitation';

const getEmail = (row: FleetMemberRow) => row.data.email ?? '';

const hasName = (row: FleetMemberRow) => {
  if (isInvitation(row)) return false;

  return !!row.data.firstName || !!row.data.lastName || !!row.data.name;
};

const getName = (row: FleetMemberRow) => {
  if (isInvitation(row)) return '';

  const firstName = row.data.firstName ?? '';
  const lastName = row.data.lastName ?? '';
  return [firstName, lastName].filter(Boolean).join(' ') || row.data.name || '';
};

const getRoles = (row: FleetMemberRow) => {
  if (isInvitation(row)) return [];

  return row.data.roles ?? [];
};

const getRoleNames = (row: FleetMemberRow) =>
  getRoles(row).map((role) => role.name);

const getIntegrations = (row: FleetMemberRow) =>
  isUser(row) ? row.data.integrations ?? [] : [];

const getIntegrationNames = (row: FleetMemberRow) =>
  getIntegrations(row).map(getIntegrationName);

const getDevices = (row: FleetMemberRow) =>
  isUser(row) ? row.data.devices ?? [] : [];

const getCompanions = (row: FleetMemberRow) =>
  isUser(row) ? row.data.companions ?? [] : [];

const getAccountIssues = (row: FleetMemberRow) => {
  if (isInvitation(row)) return [];

  return (
    row.data.issues?.map((issue) => issue.title ?? '').filter(Boolean) ?? []
  );
};

const getIntegrationIssue = (integration: FleetIntegration) => {
  if (!integration.hasIssue) return '';

  const date = formatShortDate(integration.createdAt, {
    showTime: true,
    showYear: true,
  });

  const integrationName = getIntegrationName(integration);

  return `${integrationName} integration (${date}) needs reauthentication.`;
};

const getIntegrationIssues = (row: FleetMemberRow) =>
  getIntegrations(row).map(getIntegrationIssue).filter(Boolean) ?? [];

const getIssues = (row: FleetMemberRow) => [
  ...getAccountIssues(row),
  ...getIntegrationIssues(row),
];

const getDeviceLastSeenDate = (device: FleetDevice) =>
  device.lastSeen ? new Date(device.lastSeen) : null;

const getIntegrationLastSeenDate = (integration: FleetIntegration) =>
  integration.createdAt ? new Date(integration.createdAt) : null;

const getCompanionAppLastSeenDate = (companion: FleetCompanion) =>
  companion.lastSeen ? new Date(companion.lastSeen) : null;

const getLastSeenDate = (row: FleetMemberRow) => {
  if (isInvitation(row)) return;

  const deviceDates = row.data.devices?.map(getDeviceLastSeenDate) ?? [];

  const companionDates =
    row.data.companions?.map(getCompanionAppLastSeenDate) ?? [];

  const integrationDates =
    row.data.integrations?.map(getIntegrationLastSeenDate) ?? [];

  const allDates = [...deviceDates, ...companionDates, ...integrationDates]
    .filter(Boolean)
    .sort() as Date[];

  return allDates[0];
};

export const fleetMemberRowHelpers = {
  getAccountIssues,
  getCompanionAppLastSeenDate,
  getCompanions,
  getDeviceLastSeenDate,
  getDevices,
  getEmail,
  getIntegrationIssue,
  getIntegrationIssues,
  getIntegrationLastSeenDate,
  getIntegrationNames,
  getIntegrations,
  getIssues,
  getLastSeenDate,
  getName,
  getRoleNames,
  getRoles,
  hasName,
  isInvitation,
  isUser,
};
