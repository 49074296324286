import { FleetRole } from 'src/api/endpoints/cloudEnterpriseAdminApi.types';

type FleetRoleMerged = FleetRole & {
  hasRole?: boolean;
  /**
   * If `true`, the user has a role that is not globally available (can only be removed).
   * This could mean that the role has been deprecated or they have been assigned
   * the role in some other way, for instance through the Auth0 dashboard directly.
   */
  isNotGloballyAvailableRole?: boolean;
};

/**
 * Merge the global role list with a specific users current roles.
 */
export function mergeRoleLists(
  /** Globally available roles from admin api */
  availableRoles: FleetRole[],
  /** Roles that is assigned to a specific user */
  currentRoles: FleetRole[]
) {
  const allRoles: FleetRoleMerged[] = [...availableRoles];

  // Add the current roles that is not in the global list and mark them as
  // not globally available (these roles can only be removed).
  currentRoles.forEach((role) => {
    const matchIndex = allRoles.findIndex(({ id }) => id === role.id);

    if (matchIndex > -1) {
      // User is assigned one of the globally available roles, update its status...
      allRoles[matchIndex] = { ...allRoles[matchIndex], hasRole: true };
    } else {
      // If we find a role on the member that is not in the globally availableRoles,
      // we still want to show it in the list, but marked as not globally available
      // because they can not be added back if removed
      // (unless through auth0 dashboard directly).
      allRoles.push({
        ...role,
        isNotGloballyAvailableRole: true,
        hasRole: true,
      });
    }
  });
  return allRoles;
}
