import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { Spinner } from 'src/components';
import { sanitizeRedirectUrl } from 'src/utils/sanitizeRedirectUrl';

const loginSearchSchema = z.object({
  redirect: z.string().startsWith('/').optional(),
  connection: z.string().optional(),
  login_hint: z.string().optional(),
  screen_hint: z.enum(['signup', 'login']).optional(),
  organization: z.string().optional(),
  prompt: z.enum(['none']).optional(),
  invitation: z.string().optional(),
});

export const Route = createFileRoute('/(public)/login')({
  validateSearch: zodSearchValidator(loginSearchSchema),
  component: () => {
    const auth = useAuth0();
    const search = Route.useSearch();

    const connection =
      search.connection === 'email'
        ? 'Username-Password-Authentication'
        : search.connection;

    void auth.loginWithRedirect({
      authorizationParams: {
        prompt: search.prompt,
        organization: search.organization,
        connection,
        login_hint: search.login_hint,
        screen_hint: search.screen_hint,
        invitation: search.invitation,
      },
      appState: {
        returnTo: sanitizeRedirectUrl(search.redirect),
      },
    });

    return <Spinner />;
  },
});
