import { formatDistanceToNow, isDate } from 'date-fns';

import { formatShortDate } from 'src/utils/formatDate';

import { Tooltip } from './Tooltip';

export function TimeSinceNow(props: { date?: Date | number | string }) {
  if (!isDate(props.date)) {
    return <span>-</span>;
  }
  const timeSinceNowText = formatDistanceToNow(props.date, { addSuffix: true });

  return (
    <Tooltip
      asChild
      title={formatShortDate(props.date, {
        showTime: true,
        showYear: true,
      })}
    >
      <span>{timeSinceNowText}</span>
    </Tooltip>
  );
}
