import { useEffect, useRef, useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { CheckoutType } from 'src/api/endpoints/storeApi.types';
import { getCheckoutUrl } from 'src/apps/checkout/app/useCheckoutType';
import heroOffer from 'src/assets/img/hero-offer.webp';
import { Button } from 'src/components';
import { ImageWithFallback } from 'src/components/ImageWithFallback';
import { StackedSheet } from 'src/components/StackedSheet';
import { SKU } from 'src/utils/skus';
import { useWindowSize } from 'src/utils/useWindowSize';
import { valueProps } from 'src/utils/valueProps';

import { Price } from '../Price';
import { ExpirationText } from './ExpirationText';

export const ActivateOffer = ({
  type,
  title,
  text,
  buttonText,
  buttonFixed,
  analytics,
  expirationDate,
}: {
  type: CheckoutType;
  title: string;
  text: string;
  buttonText?: string;
  buttonFixed?: boolean;
  analytics?: {
    text: string;
    location: string;
    action: string;
  };
  expirationDate: string | null;
}) => {
  const ctaRef = useRef<HTMLAnchorElement>(null);
  const [showFixedButton, setShowFixedButton] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (ctaRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          setShowFixedButton(
            windowSize.width < 640 &&
              !entry.isIntersecting &&
              !!ctaRef.current &&
              ctaRef.current.getBoundingClientRect().y > 0 // prevents showing when scrolling to bottom
          );
        },
        { threshold: 1.0 }
      );
      observer.observe(ctaRef.current);
      return () => {
        if (ctaRef.current) {
          observer.unobserve(ctaRef.current);
        }
      };
    }
  }, [ctaRef, windowSize.width]);

  // todo: Add missing value prop. Omitted to not conflict with new checkout flow designs
  const listItems = [
    [
      valueProps.connect.unlimitedCloudStorage,
      valueProps.connect.createAndEditInOurApps,
    ],
    [
      valueProps.connect.automaticSync,
      valueProps.connect.remarkableProtectionPlan,
    ],
  ];

  return (
    <>
      <StackedSheet dataCy="connect-offer-landing">
        <div className="flex flex-col lm:flex-row">
          <div className="flex flex-col gap-16 text-left lm:w-3/5 lm:pr-64">
            <ExpirationText expirationDate={expirationDate} />
            <Typography variant="responsive-heading-lg" className="text-pretty">
              {title}
            </Typography>
            <Typography variant="body-md-regular">{text}</Typography>

            <Typography
              variant="interface-sm-medium"
              className="hidden lm:block"
            >
              Connect benefits
            </Typography>
            <div
              data-cy="connect-offer-perks"
              className="flex w-full flex-col items-start justify-center gap-16 text-ink-4 lm:flex-row"
            >
              {listItems.map((list, index) => (
                <ul
                  className="flex w-full flex-col gap-16 lm:w-1/2"
                  key={index}
                >
                  {list.map((item) => (
                    <li
                      key={item.title}
                      className="flex items-center gap-8 py-2"
                    >
                      <item.icon size={24} />

                      <Typography
                        variant="interface-sm-regular"
                        className="m-0 text-left leading-125"
                      >
                        {item.title}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ))}
            </div>

            <Button
              //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
              ref={ctaRef as any}
              as="a"
              variant="primary"
              to={getCheckoutUrl(type)}
              className="my-20 w-full lm:w-fit"
              data-cy="landing-next-step"
              analytics={analytics}
            >
              <span data-cy={`${type}-activate-button`}>{buttonText}</span>
              <ArrowRight />
            </Button>

            <Typography variant="interface-sm-regular">
              <span>
                Connect costs <Price sku={SKU.connectMonthly} />
                /mo when your free trial ends. <b>Cancel anytime.</b>
              </span>
            </Typography>
          </div>

          <div className="w-full lm:w-1/2">
            <ImageWithFallback
              className=" justify-center lm:flex "
              imgClassName="py-8 lm:max-h-full max-h-[200px] w-full object-cover"
              sources={[
                {
                  type: 'webp',
                  url: heroOffer,
                },
              ]}
            />
          </div>
        </div>
      </StackedSheet>

      {buttonFixed && showFixedButton && (
        <div className="fixed bottom-0 right-0 z-50 mx-0 w-full bg-neutral-light-1 px-32 shadow-xl shadow-black">
          <Button
            as="a"
            variant="primary"
            to={getCheckoutUrl(type)}
            className="shadow-grayscale-gray-500 my-20 w-full shadow-md lm:w-fit"
            data-cy="landing-next-step"
            analytics={analytics}
          >
            <span data-cy={`${type}-activate-button`}>{buttonText}</span>
            <ArrowRight />
          </Button>
        </div>
      )}
    </>
  );
};
